import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { getVideos, approveVideo } from '../api/Videos.js';

import "../../node_modules/video-react/dist/video-react.css"; // import css

// import ReactPlayer from 'react-player';
import { Player } from 'video-react';

import styled from 'styled-components';

// inline stylesheet
const Styles = styled.div`
	.card-horizontal {
		display: flex;
		flex: 1 1 auto;
	}
`;

class VideoModerator extends Component {

	constructor(props) {

		console.log("constructor");

		super(props);

		this.state = {
			videos: []
		};
	}

	componentDidMount() {
		getVideos((videos) => {
			this.setState({ videos: videos});
		});
	}

	handleMassApprove = () => {
		console.log("handleMassApprove");
		for (let index in this.state.videos) {
			let video = this.state.videos[index];
			console.log(video);

			approveVideo(video.uuid, (callBack) => {

			})
		}
	}

	handleApprove = (videoId) => {
		approveVideo(videoId, (callBack) => {
			if (callBack.status === 201) {
				window.location.reload();
			}
		})
	}

	handleReject = (videoId) => {
		console.log("handleReject");
		console.log(videoId);
	}

	render() {

		let w45 = {
			width: '45%',
		};

		return (
			<Styles>
				<Container>
					<Row>
						<Col>
							<h1 className="display-4 mt-4 mb-5">Video Moderator</h1>
						</Col>
					</Row>
					<Row>

						{
							(this.state.videos.length > 0) ? (

								<button className="btn btn-lg btn-success" onClick={this.handleMassApprove}>Approve All</button>,

								this.state.videos.map((video, video_key) => {
									return (
										<Col md={6} key={video_key}>
											<div className="card mb-4">
												<div className="card-body">
													<Player
													  playsInline
													  src={ video.url }
													/>
													<h4 className="card-title mt-3">{ video.title }</h4>
													<p className="card-text">{ video.description }</p>
													<div className="mt-auto">
														<button className="btn btn-lg btn-success m-2" onClick={this.handleApprove.bind(this, video.uuid)} style={ w45 }>Approve</button>
														<button className="btn btn-lg btn-danger m-2" onClick={this.handleReject.bind(this, video.uuid)} style={ w45 }>Reject</button>
													</div>
												</div>
											</div>
										</Col>
									);
								})
							) : (
								<div className="my-3">No videos to display.</div>
							)
						}

					</Row>
				</Container>
			</Styles>
		);
	}

}

export default VideoModerator