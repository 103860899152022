import React, { Component, Fragment, useCallback } from 'react';
import { Container, Row, Col, Pagination, FormControl, Form, FormGroup, Modal, Button, Spinner } from 'react-bootstrap';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { BlockPicker } from 'react-color';

import axios from "axios";
import config from "../config";
import Videos from "../components/Videos.js";
import "../components/LaunchModal.css";

import { getSingleContest, updateContest, createContest, deleteContest, createHost, deleteHost, createSponsor, deleteSponsor, createPrize, deletePrize, getContestRounds, setContestRoundFeatured } from "../api/Contests.js";

/* drag and drop handler */
import { useDropzone } from 'react-dropzone';

function DragAndDropDialog(props) {
	const onDrop = useCallback(acceptedFiles => {
		props.callback(acceptedFiles, props.ddkey);
	}, [])

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	return (
		<div { ...getRootProps() } className={ props.styles }>
			<input { ...getInputProps() } />
			{
				isDragActive ?
					<span>Drop the file here ...</span> : (
						props.selectedFile ?
							<div className="m-2">{ props.selectedFile }</div>
						: (
							<div className="ddBorder m-0 py-4 px-2">
								<div>
									{ props.prompt }, or
									<span className="text-info m-2">Browse</span>
								</div>
								{
									(props.format) ? (
										<Fragment>
											<span className="ddDialogFormat py-2 text-muted">{ props.format }</span>
											{
												(props.dims) ? <span className="ddDialogFormat px-2">{ props.dims }</span> : ""
											}
										</Fragment>
									) : ("")
								}
							</div>
						)
					)
			}
		</div>
	);
}



export default class ContestEditor extends Component {

		constructor(props) {
			super(props);

			this.state = {
				isAuthenticated: props.isAuthenticated,
				contestData: [],
				currentContestData: {},
				currentContestRounds: {},
				currentContestId: props.match.params.id,
				loadingDone: false,
				showEditContestForm: false,
				currentPage: 1,
				maxPage: 99999, // arbitrarily large number of pages
				resultsPerPage: 50,
				toastMsg: "",
				toastSuccess: false,
				toastTime: 7000,
				newHost: { name:'', tagline:'', description:'', link:'', image_url:'' },
				newHostImage: { image_preview:'', file_data:'', file_name:'' },
				newSponsor: { name:'', tagline:'', description:'', link:'', image_url:'' },
				newSponsorImage: { image_preview:'', file_data:'', file_name:'' },
				newPrize: { name:'', tagline:'', description:'', link:'', image_url:'' },
				newPrizeImage: { image_preview:'', file_data:'', file_name:'' }
			};

			this.getSingleContestCallback = this.getSingleContestCallback.bind(this);
			this.getContestRoundsCallback = this.getContestRoundsCallback.bind(this);
			this.putContestsCallback = this.putContestsCallback.bind(this);
			this.createContestsCallback = this.createContestsCallback.bind(this);
			this.deleteContestsCallback = this.deleteContestsCallback.bind(this);
			this.handleStartDateChange = this.handleStartDateChange.bind(this);
			this.handleEndDateChange = this.handleEndDateChange.bind(this);

			this.createHostCallback = this.createHostCallback.bind(this);
			this.deleteHostCallback = this.deleteHostCallback.bind(this);
			this.showHostsModalCallback = this.showHostsModalCallback.bind(this);
			this.createSponsorCallback = this.createSponsorCallback.bind(this);
			this.deleteSponsorCallback = this.deleteSponsorCallback.bind(this);
			this.showSponsorsModalCallback = this.showSponsorsModalCallback.bind(this);
			this.createPrizeCallback = this.createPrizeCallback.bind(this);
			this.deletePrizeCallback = this.deletePrizeCallback.bind(this);
			this.showPrizesModalCallback = this.showPrizesModalCallback.bind(this);
		}


		showHostsModalCallback() {
			this.setState({ showHostsModal: false });
		}

		showSponsorsModalCallback() {
			this.setState({ showSponsorsModal: false });
		}

		showPrizesModalCallback() {
			this.setState({ showPrizesModal: false });
		}

		refreshContests() {
			this.getSingleContestData(this.state.currentContestId, this.state.currentPage, this.state.resultsPerPage);
			// flag if contest create form
			if (this.state.currentContestId !== "create") {
				this.getContestRoundsData(this.state.currentContestId);
			} else {
				this.showCreateContestForm();
			}
		}

		getSingleContestData(id, page, size) {
			this.setState({ loadingDone: false });
			getSingleContest(this.getSingleContestCallback, { id: id, page: page, pageSize: size });
		}

		getSingleContestCallback(data) {
			console.log("getSingleContestCallback", data);
			this.selectIndividualContest(data);
			this.setState({ currentContestData: data, currentHostsData: data.hosts, currentSponsorsData: data.sponsors, currentPrizesData: data.prizes, loadingDone: true, maxVideoPage: (data.length > 0) ? this.state.maxVideoPage : this.state.currentVideoPage });
		}

		getContestRoundsData(id) {
			// this.setState({ loadingDone: false });
			getContestRounds(this.getContestRoundsCallback, { id: id });
		}

		getContestRoundsCallback(data) {
			console.log("getContestRoundsCallback", data);
			this.setState({ currentContestRounds: data });
		}

		setFeaturedRound(event, roundId, setFeatured) {
			event.preventDefault();

			setFeatured = setFeatured || "set";
			let confirmMessage = ( setFeatured === "set" ? 'Are you sure want to feature this round?' : 'Are you sure you want to unfeature this round?' );

			if(roundId)
				confirmAlert({
					title: 'Confirm',
					message: confirmMessage,
					buttons: [
						{
							label: 'Yes',
							onClick: () => setContestRoundFeatured(this.setFeaturedRoundCallback, { id: roundId })
						},
						{
							label: 'No',
							onClick: () => { }
						}
					]
				});
			else
				this.makeToast("No contest round selected", null);
		}

		setFeaturedRoundCallback(data) {
			console.log("setFeaturedRoundCallback", data);
			window.location.reload();
		}

		handleContestDelete(event) {
			event.preventDefault();

			if(this.state.currentContestId)
				confirmAlert({
					title: 'Confirm',
					message: 'Are you sure want to delete this contest?',
					buttons: [
						{
							label: 'Yes',
							onClick: () => deleteContest(this.deleteContestsCallback, { id: this.state.currentContestId })
						},
						{
							label: 'No',
							onClick: () => { }
						}
					]
				});
			else
				this.makeToast("No contest selected", null);
		}

		handleContestImageSave() {
			// upload images
			var tempStatus = { ...(this.state.heroWebFileName && { hero_image_web: false } ), ...(this.state.heroPhoneFileName && { hero_image_phone: false }) };
			this.setState({ imageUploadStatus: { ...tempStatus } });

			if (this.state.heroWebFileName) {
				console.log("uploading hero_image_web");
				this.handleImageUpload('hero_image_web');
			}

			if (this.state.heroPhoneFileName) {
				console.log("uploading hero_image_phone");
				this.handleImageUpload('hero_image_phone');
			}

			if (this.state.newHostImage.file_name) {
				console.log("uploading host_image_url");
				this.handleImageUpload('host_image_url');
			}
			if (this.state.newSponsorImage.file_name) {
				console.log("uploading sponsor_image_url");
				this.handleImageUpload('sponsor_image_url');
			}
			if (this.state.newPrizeImage.file_name) {
				console.log("uploading prize_image_url");
				this.handleImageUpload('prize_image_url');
			}
		}

		handleHostSave(event) {
			if(event) { event.preventDefault(); }

			// input validation
			if(!this.state.newHost.name) {
				this.makeToast("Name field is required", null);
				return;
			}

			// get host fields for save request
			let body = {
				name: this.state.newHost.name,
				tagline: this.state.newHost.tagline,
				description: this.state.newHost.description,
				link: this.state.newHost.link,
				image_url: this.state.newHost.image_url
			};
			console.log("request body", body);

			createHost(this.createHostCallback, { id: this.state.currentContestId, body: body });
		}

		handleHostDelete(event, hostId) {
			event.preventDefault();

			if(hostId)
				confirmAlert({
					title: 'Confirm',
					message: 'Are you sure want to delete this host?',
					buttons: [
						{
							label: 'Yes',
							onClick: () => deleteHost(this.deleteHostCallback, { id: hostId })
						},
						{
							label: 'No',
							onClick: () => { }
						}
					]
				});
			else
				this.makeToast("No contest selected", null);
		}

		handleSponsorSave(event) {
			if(event) { event.preventDefault(); }

			// input validation
			if(!this.state.newSponsor.name) {
				this.makeToast("Name field is required", null);
				return;
			}

			// get sponsor fields for save request
			let body = {
				name: this.state.newSponsor.name,
				tagline: this.state.newSponsor.tagline,
				description: this.state.newSponsor.description,
				link: this.state.newSponsor.link,
				image_url: this.state.newSponsor.image_url
			};
			console.log("request body", body);

			createSponsor(this.createSponsorCallback, { id: this.state.currentContestId, body: body });
		}

		handleSponsorDelete(event, sponsorId) {
			event.preventDefault();

			if(sponsorId)
				confirmAlert({
					title: 'Confirm',
					message: 'Are you sure want to delete this sponsor?',
					buttons: [
						{
							label: 'Yes',
							onClick: () => deleteSponsor(this.deleteSponsorCallback, { id: sponsorId })
						},
						{
							label: 'No',
							onClick: () => { }
						}
					]
				});
			else
				this.makeToast("No contest selected", null);
		}

		handlePrizeSave(event) {
			if(event) { event.preventDefault(); }

			// input validation
			if(!this.state.newPrize.name) {
				this.makeToast("Name field is required", null);
				return;
			}

			// get prize fields for save request
			let body = {
				name: this.state.newPrize.name,
				tagline: this.state.newPrize.tagline,
				description: this.state.newPrize.description,
				link: this.state.newPrize.link,
				image_url: this.state.newPrize.image_url
			};
			console.log("request body", body);

			createPrize(this.createPrizeCallback, { id: this.state.currentContestId, body: body });
		}

		handlePrizeDelete(event, prizeId) {
			event.preventDefault();

			if(prizeId)
				confirmAlert({
					title: 'Confirm',
					message: 'Are you sure want to delete this prize?',
					buttons: [
						{
							label: 'Yes',
							onClick: () => deletePrize(this.deletePrizeCallback, { id: prizeId })
						},
						{
							label: 'No',
							onClick: () => { }
						}
					]
				});
			else
				this.makeToast("No contest selected", null);
		}


		handleContestSave(event) {
			if(event) { event.preventDefault(); }

			// input validation
			if(!this.state.currentContestName || !this.state.currentContestDescription
					|| !this.state.currentContestTagline || !this.state.currentContestStart
					|| !this.state.currentContestEnd) {

				this.makeToast("All fields are required", null);
				return;
			}

			if(this.state.currentContestEnd.getTime() <= this.state.currentContestStart.getTime()) {
				this.makeToast("End date must be later than start date", null);
				return;
			}

			/* get contest fields for save request */
			let body = {
				name: this.state.currentContestName,
				description: this.state.currentContestDescription,
				tagline: this.state.currentContestTagline,
				start_date: this.state.currentContestStart.toISOString().split("T")[0],
				end_date: this.state.currentContestEnd.toISOString().split("T")[0]
			};
			/* add optional fields */
			if (this.state.currentContestMaxLength) {
				body.video_max_length_seconds = this.state.currentContestMaxLength;
			}
			if (this.state.currentContestGradientStart) {
				body.colour_gradient_start = this.state.currentContestGradientStart;
			}
			if (this.state.currentContestGradientEnd) {
				body.colour_gradient_end = this.state.currentContestGradientEnd;
			}
			if (this.state.currentContestTerms) {
				body.terms_and_conditions = this.state.currentContestTerms;
			}
			if (this.state.currentContestExtraInfo) {
				body.extra_info = this.state.currentContestExtraInfo;
			}
			if (this.state.currentContestVisible) {
				body.visible = this.state.currentContestVisible;
			}
			if (this.state.currentContestHeroWeb) {
				body.hero_image_web_url = this.state.currentContestHeroWeb;
			}
			if (this.state.currentContestHeroPhone) {
				body.hero_image_phone_url = this.state.currentContestHeroPhone;
			}
			console.log("request body", body);

			if(this.state.editContestFormMode === "edit")
				updateContest(this.putContestsCallback, { id: this.state.currentContestId, body: body });
			else if(this.state.editContestFormMode === "create")
				createContest(this.createContestsCallback, { body: body });
		}

		putContestsCallback(response, message) {

			this.setState({ showEditContestForm: false });

			if(response) {
				this.makeToast("Contest saved!", true);
				this.refreshContests();
			} else {
				this.makeToast("Error saving contest... " + message, false);
				this.setState({ showEditContestForm: true });
			}
		}

		createContestsCallback(response, message) {
			this.setState({ showEditContestForm: false });

			if(response) {
				console.log(response);
				this.makeToast("Contest created!", true);
				// this.setState({ currentContestId: })
				this.refreshContests();
			} else {
				this.makeToast("Error creating contest... " + message, false);
				this.setState({ showEditContestForm: true });
			}
		}

		deleteContestsCallback(response, message) {
			//console.log("delete response");
			//console.log(message);

			this.setState({ showEditContestForm: false });

			if(response) {
				this.makeToast("Contest deleted!", true);
				this.refreshContests();
			} else {
				this.makeToast("Error deleting contest... " + message, false);
				this.setState({ showEditContestForm: true });
			}
		}

		createHostCallback(response, message) {
			this.setState({ showEditContestForm: false });

			if(response) {
				console.log(response);
				this.makeToast("Host created!", true);
				this.setState({ showHostsModal: false });
				this.refreshContests();
			} else {
				this.makeToast("Error creating host... " + message, false);
				this.setState({ showEditContestForm: true });
			}
		}

		deleteHostCallback(response, message) {
			this.setState({ showEditContestForm: false });

			if(response) {
				this.makeToast("Host deleted!", true);
				this.refreshContests();
			} else {
				this.makeToast("Error deleting host... " + message, false);
				this.setState({ showEditContestForm: true });
			}
		}

		createSponsorCallback(response, message) {
			this.setState({ showEditContestForm: false });

			if(response) {
				console.log(response);
				this.makeToast("Sponsor created!", true);
				this.refreshContests();
			} else {
				this.makeToast("Error creating sponsor... " + message, false);
				this.setState({ showEditContestForm: true });
			}
		}

		deleteSponsorCallback(response, message) {
			this.setState({ showEditContestForm: false });

			if(response) {
				this.makeToast("Sponsor deleted!", true);
				this.refreshContests();
			} else {
				this.makeToast("Error deleting sponsor... " + message, false);
				this.setState({ showEditContestForm: true });
			}
		}

		createPrizeCallback(response, message) {
			this.setState({ showEditContestForm: false });

			if(response) {
				console.log(response);
				this.makeToast("Prize created!", true);
				this.refreshContests();
			} else {
				this.makeToast("Error creating prize... " + message, false);
				this.setState({ showEditContestForm: true });
			}
		}

		deletePrizeCallback(response, message) {
			this.setState({ showEditContestForm: false });

			if(response) {
				this.makeToast("Prize deleted!", true);
				this.refreshContests();
			} else {
				this.makeToast("Error deleting prize... " + message, false);
				this.setState({ showEditContestForm: true });
			}
		}

		selectIndividualContest(data) {
			this.setState({
				showEditContestForm: true,
				editContestFormMode: "edit",
				currentContestId: data.id,
				currentContestName: data.name,
				currentContestDescription: data.description,
				currentContestTagline: data.tagline,
				currentContestMaxLength: data.video_max_length_seconds,
				currentContestTerms: data.terms_and_conditions,
				currentContestExtraInfo: data.extra_info,
				currentContestVisible: data.visible,
				currentContestHeroWeb: data.hero_image_web_url,
				currentContestHeroPhone: data.hero_image_phone_url,
				currentContestGradientStart: data.colour_gradient_start || '#9929EA',
				currentContestGradientEnd: data.colour_gradient_end || '#5808FB',
				heroWebFileName: "",
				heroWebFilePreview: "",
				heroPhoneFileName: "",
				heroPhoneFilePreview: "",
				currentContestStart: new Date(data.start_date),
				currentContestEnd: new Date(data.end_date),
				newHost: { name:'', tagline:'', description:'', link:'', image_url:'' },
				newHostImage: { image_preview:'', file_data:'', file_name:'' },
				newSponsor: { name:'', tagline:'', description:'', link:'', image_url:'' },
				newSponsorImage: { image_preview:'', file_data:'', file_name:'' },
				newPrize: { name:'', tagline:'', description:'', link:'', image_url:'' },
				newPrizeImage: { image_preview:'', file_data:'', file_name:'' }
			});

		}

		showCreateContestForm() {
			this.setState({
				showEditContestForm: true,
				editContestFormMode: "create",
				currentContestId: "",
				currentContestName: "",
				currentContestDescription: "",
				currentContestTagline: "",
				currentContestVisible: 0,
				currentContestHeroWeb: "",
				currentContestHeroPhone: "",
				heroWebFileName: "",
				heroWebFilePreview: "",
				heroPhoneFileName: "",
				heroPhoneFilePreview: "",
				currentContestStart: "",
				currentContestEnd: ""
			});
		}

		/*
			Stop viewing single contest, review to all contests list. Reset paging state.
		*/
		/*closeEditContestForm() {
			this.setState({
				showEditContestForm: false,
				currentPage: 1,
				resultsPerPage: 10,
				maxPage: 99999,
				currentContestId: ""
			});
		}*/

		componentDidMount() {
			this.refreshContests();
		}

		componentWillUnmount() {

		}


		/*
			Display a feedback message to the user
		*/
		makeToast(msg, success) {

			this.setState({ toastMsg: msg, toastSuccess: success });
			document.getElementById("toast").scrollIntoView();

			//clear toast after certain amount of time
			setTimeout(() => {
				this.setState({ toastMsg: "", toastSuccess: null });
			}, this.state.toastTime);
		}


		/*
			Perform the image upload
		*/
		handleImageUpload(type) {
			console.log("handleUpload");

			let file;
			let fileParts;

			if (type === 'hero_image_web') {
				file = this.state.heroWebFileData[0];
				// Split the filename to get the name and type
				fileParts = this.state.heroWebFileName.split('.');
			} else if (type === 'hero_image_phone') {
				file = this.state.heroPhoneFileData[0];
				fileParts = this.state.heroPhoneFileName.split('.');
			} else if (type === 'host_image_url') {
				file = this.state.newHostImage.file_data[0];
				fileParts = this.state.newHostImage.file_name.split('.');
			} else if (type === 'sponsor_image_url') {
				file = this.state.newSponsorImage.file_data[0];
				fileParts = this.state.newSponsorImage.file_name.split('.');
			} else if (type === 'prize_image_url') {
				file = this.state.newPrizeImage.file_data[0];
				fileParts = this.state.newPrizeImage.file_name.split('.');
			}
			// console.log(file);
			let fileName = fileParts[0].replace(/[^A-Z0-9]+/ig, "_") + Math.random().toString(36).substr(1, 16);
			let fileType = fileParts[1];

			console.log("fileName: " + fileName);

			/*********************************
			* S3 Configuration
			*********************************/
			var aws = require('aws-sdk');
			require('dotenv').config();

			// Configure aws with your accessKeyId and your secretAccessKey
			aws.config.update({
				region: config.s3.REGION,
				accessKeyId: config.s3.AWS_ACCESS_KEY_ID, //process.env.AWSAccessKeyId,
				secretAccessKey: config.s3.AWS_SECRET_ACCESS_KEY //process.env.AWSSecretKey
			})

			//console.log(aws.config);

			const S3_BUCKET = config.s3.BUCKET; //process.env.bucket

			console.log("s3_bucket", S3_BUCKET);

			const s3 = new aws.S3(); // Create a new instance of S3

			// Set up the payload of what we are sending to the S3 api
			const s3Params = {
				Bucket: S3_BUCKET,
				Key: fileName,
				Expires: 500,
				ContentType: fileType,
				ACL: 'public-read'
			};

			// Make a request to the S3 API to get a signed URL which we can use to upload our file
			s3.getSignedUrl('putObject', s3Params, (err, data) => {
				if(err) {
					console.log("Error", err);
					return;
					//res.json({success: false, error: err})
				}
				// Data payload of what we are sending back, the url of the signedRequest and a URL where we can access the content after its saved. const returnData = {
				const returnData = {
					signedRequest: data,
					url: `https://${S3_BUCKET}.s3.amazonaws.com/${fileName}`
				};

				console.log("returnData", returnData);

				var signedRequest = returnData.signedRequest;
				var url = returnData.url;

				// Put the fileType in the headers for the upload
				var options = {
					headers: {
						'Content-Type': fileType
					}
				};
				axios.put(signedRequest,file,options)
					.then(result => {
						console.log("Response from s3", result, url);

						if (type === 'hero_image_web') {
							this.setState({ currentContestHeroWeb: url, heroWebFileName: "" });
							this.handleContestSave();
						} else if (type === 'hero_image_phone') {
							this.setState({ currentContestHeroPhone: url, heroPhoneFileName: "" });
							this.handleContestSave();
						}

						if (type === 'host_image_url') {
							this.setState({ newHostImage: { image_preview:'', file_data:'', file_name:'' } });
							let hostData = this.state.newHost;
							hostData.image_url = url;
							this.setState({ newHost: hostData });
							console.log("hostData", hostData);
							// this.handleHostSave();
						} else if (type === 'sponsor_image_url') {
							this.setState({ newSponsorImage: { image_preview:'', file_data:'', file_name:'' } });
							let sponsorData = this.state.newSponsor;
							sponsorData.image_url = url;
							this.setState({ newSponsor: sponsorData });
							console.log("sponsorData", sponsorData);
							// this.handleSponsorSave();
						} else if (type === 'prize_image_url') {
							this.setState({ newPrizeImage: { image_preview:'', file_data:'', file_name:'' } });
							let prizeData = this.state.newPrize;
							prizeData.image_url = url;
							this.setState({ newPrize: prizeData });
							console.log("prizeData", prizeData);
							// this.handlePrizeSave();
						}

					})
					.catch(error => {
						console.log(JSON.stringify(error));
					})

			});
		}

		dragAndDropFileChange = (files, key) => {
			console.log("dragAndDropFileChange");
			console.log(files);

			let previewFiles = [];
			previewFiles = files.map(file => Object.assign(file, {
				preview: URL.createObjectURL(file)
			}));

			if (key === "hero_image_web") {
				console.log("setting hero image web");
				this.setState({ heroWebFileName: files[0].name, heroWebFileData: files, heroWebFilePreview: previewFiles });
				this.handleContestImageSave();
			} else if (key === "hero_image_phone") {
				console.log("setting hero image phone");
				this.setState({ heroPhoneFileName: files[0].name, heroPhoneFileData: files, heroPhoneFilePreview: previewFiles });
				this.handleContestImageSave();
			}

			if (key === "host_image_url") {
				console.log("setting host image");
				let hostImageData = this.state.newHostImage;
				hostImageData.file_name = files[0].name;
				hostImageData.file_data = files;
				hostImageData.image_preview = previewFiles;
				this.setState({ newHostImage: hostImageData });
				this.handleContestImageSave();
			} else if (key === "sponsor_image_url") {
				console.log("setting sponsor image");
				let sponsorImageData = this.state.newSponsorImage;
				sponsorImageData.file_name = files[0].name;
				sponsorImageData.file_data = files;
				sponsorImageData.image_preview = previewFiles;
				this.setState({ newSponsorImage: sponsorImageData });
				this.handleContestImageSave();
			} else if (key === "prize_image_url") {
				console.log("setting prize image");
				let prizeImageData = this.state.newPrizeImage;
				prizeImageData.file_name = files[0].name;
				prizeImageData.file_data = files;
				prizeImageData.image_preview = previewFiles;
				this.setState({ newPrizeImage: prizeImageData });
				this.handleContestImageSave();
			}
		}



		render() {
			return (this.state.isAuthenticated) ? this.renderAuth() : this.renderUnauth();
		}

		buildPageControlForm(label) {
			return (
				<Form className="mt-3">
					<Form.Group>
						<Row>
							<Col sm={4}>
								<Row>
									<Col sm={4}></Col>
									<Col sm={8}>
										<Pagination>
											<Pagination.Prev onClick={ () => { this.previousPage() } }/>

											<Pagination.Item>
											{
												this.state.currentPage
											}
											</Pagination.Item>

											<Pagination.Next onClick={ () => { this.nextPage() } }/>
										</Pagination>
									</Col>
								</Row>
							</Col>
							<Col sm={8}>
								<Row>
									<Col sm={4}></Col>
									<Col sm={4} className="text-right">
										<Form.Label className="text-muted">{ label } per page</Form.Label>
									</Col>
									<Col sm={2}>
										<Form.Control as="select" value={ this.state.resultsPerPage } onChange={ (e) => { this.handleResultsPerPageChange(e) } }>
											<option value="50">50</option>
											<option value="25">25</option>
											<option value="10">10</option>
										</Form.Control>
									</Col>
									<Col sm={2}></Col>
								</Row>
							</Col>
						</Row>
					</Form.Group>
					<hr/>
				</Form>
			);
		}

		/*buildContestEditModal(props) {
			// required props
			// type (host, sponsor, prize)
			// onHideCallback
			// modalTitle
			return (
				<Modal centered show={true} size="lg" className="launchModal" onHide={this.showHostsModalCallback}>
					<Modal.Header closeButton className="border-bottom-0 p-0">
						<Modal.Title className="m-3 modalTitle highlight w-100">
							Add New Host
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="p-0">
						<Container>
							<Row>
								<Col className="mx-2 pb-4 text-justify">

									<div className="HostContainer">
										<Row>
											<Col sm={8}>

												<Row>
													<Col sm={4} className="contestLabel text-muted"><Form.Label>Name</Form.Label></Col>
													<Col sm={8}>
														<FormGroup controlId={"currentContestHosts.new.name"}>
															<FormControl
																type="text"
																value={ this.state.newHost.name }
																onChange={ this.handleChangeHosts }
															/>
														</FormGroup>
													</Col>

													<Col sm={4} className="contestLabel text-muted"><Form.Label>Tagline</Form.Label></Col>
													<Col sm={8}>
														<FormGroup controlId={"currentContestHosts.new.tagline"}>
															<FormControl
																type="text"
																value={ this.state.newHost.tagline }
																onChange={ this.handleChangeHosts }
															/>
														</FormGroup>
													</Col>

													<Col sm={4} className="contestLabel text-muted"><Form.Label>Description</Form.Label></Col>
													<Col sm={8}>
														<FormGroup controlId={"currentContestHosts.new.description"}>
															<FormControl
																as="textarea"
																row="3"
																value={ this.state.newHost.description }
																onChange={ this.handleChangeHosts }
															/>
														</FormGroup>
													</Col>

													<Col sm={4} className="contestLabel text-muted"><Form.Label>Link</Form.Label></Col>
													<Col sm={8}>
														<FormGroup controlId={"currentContestHosts.new.link"}>
															<FormControl
																type="text"
																value={ this.state.newHost.link }
																onChange={ this.handleChangeHosts }
															/>
														</FormGroup>
													</Col>

													<Col sm={4} className="contestLabel text-muted"><Form.Label>Image URL</Form.Label></Col>
													<Col sm={8}>
														<FormGroup controlId={"currentContestHosts.new.image_url"}>
															<FormControl
																type="text"
																value={ this.state.newHost.image_url }
																onChange={ this.handleChangeHosts }
															/>
														</FormGroup>
													</Col>

													<Col sm={4}></Col>
													<Col sm={8}>
														<button className={"btn btn-primary" + (this.state.newHost.name ? "" : " disabled") } type="submit" onClick={ (e) => { this.handleHostSave(e) } }>Add { this.state.newHost.name }</button>
													</Col>

												</Row>
											</Col>
											<Col sm={4}>
												<div className="form-group">

													{this.state.newHostImage.image_preview ? (this.state.newHostImage.image_preview.map(file => (
														<div key={file.name}>
															<img className="img-fluid mb-2 imagePreview" src={file.preview} alt="" />
														</div>
													))) : ""}
													{!(this.state.newHostImage.image_preview) && this.state.newHost.image_url ?
														<img className="img-fluid mb-2 imagePreview" src={ encodeURI(this.state.newHost.image_url) } alt="" />
													: ""}
													{ this.state.newHost.name ?
														<DragAndDropDialog callback={ this.dragAndDropFileChange }
															prompt="Drag your host image here"
															format="Supports: jpg, png"
															dims="Max size: 2MB"
															styles="dragAndDropDialog videoUploadTextInput"
															multiple={false}
															ddkey="host_image_url"
															selectedFile={ this.state.newHostImage.file_name }/>
													: <i>Enter a Name to upload image.</i> }
												</div>
											</Col>
										</Row>
									</div>

								</Col>
							</Row>
						</Container>
					</Modal.Body>
				</Modal>
			)
		}*/


		/*
			Builds the individual contest cards.
		*/
		/*buildContestCards() {
			return this.state.contestData.map((val, i) => {

				let cardImage;
				if (val.hero_image_web_url) {
					cardImage = encodeURI(val.hero_image_web_url);
				} else if (val.hero_image_phone_url) {
					cardImage = encodeURI(val.hero_image_phone_url);
				}

				return (
					<Col md={4} className="mb-4" key={ val.name }>
						<Card className={"challengeCard"} onClick={ () => { window.location = '/contest-management/'+val.id } }>
							<Card.Img variant="top" src="img/blank-4x3.svg" className="cardImageContainer" style={{'backgroundImage': 'url('+cardImage+')'}} />
							<Card.Body>
								<Card.Title>{ val.name }</Card.Title>
								<Card.Text>
									{ val.description }
								</Card.Text>
								<Row>
									<Col xs={4} className="contestLabel text-muted">Current?</Col>
									<Col xs={8}>{ (val.contest_current) ? "Yes" : "No" }</Col>
								</Row>
								<Row>
									<Col xs={4} className="contestLabel text-muted">Start Date</Col>
									<Col xs={8}>{ val.start_date }</Col>
								</Row>
								<Row>
									<Col xs={4} className="contestLabel text-muted">End Date</Col>
									<Col xs={8}>{ val.end_date }</Col>
								</Row>
								<Row>
									<Col xs={4} className="contestLabel text-muted">Videos</Col>
									<Col xs={8}>{ val.videos_count }</Col>
								</Row>
								{
									(val.deleted_at) ? (
										<Row>
											<Col xs={4} className="contestLabel text-muted">Deleted On</Col>
											<Col xs={8}>{ val.deleted_at }</Col>
										</Row>
									) : ( "" )
								}
							</Card.Body>
						</Card>
					</Col>
				);
			});
		}*/

		renderAuth() {
			return (
				<Container>

					{/*
						this.state.showHostsModal ? <ContestAddPrizeModal modalData={this.state.newHost} modalTitle="Add New Host" type="hosts" dataContainer={this.state.newHost} currentData={this.state.currentHostsData} showModalCallback={ this.showHostsModalCallback } /> : ""
					*/}

					{ this.state.showHostsModal ?
						<Modal centered show={true} size="lg" className="launchModal" onHide={this.showHostsModalCallback}>
							<Modal.Header closeButton className="border-bottom-0 p-0">
								<Modal.Title className="m-3 modalTitle highlight w-100">
									Add New Host
								</Modal.Title>
							</Modal.Header>
							<Modal.Body className="p-0">
								<Container>
									<Row>
										<Col className="mx-2 pb-4 text-justify">

											<div className="HostContainer">
												<Row>
													<Col sm={8}>

														<Row>
															<Col sm={4} className="contestLabel text-muted"><Form.Label>Name</Form.Label></Col>
															<Col sm={8}>
																<FormGroup controlId={"currentContestHosts.new.name"}>
																	<FormControl
																		type="text"
																		value={ this.state.newHost.name }
																		onChange={ this.handleChangeHosts }
																	/>
																</FormGroup>
															</Col>

															<Col sm={4} className="contestLabel text-muted"><Form.Label>Tagline</Form.Label></Col>
															<Col sm={8}>
																<FormGroup controlId={"currentContestHosts.new.tagline"}>
																	<FormControl
																		type="text"
																		value={ this.state.newHost.tagline }
																		onChange={ this.handleChangeHosts }
																	/>
																</FormGroup>
															</Col>

															<Col sm={4} className="contestLabel text-muted"><Form.Label>Description</Form.Label></Col>
															<Col sm={8}>
																<FormGroup controlId={"currentContestHosts.new.description"}>
																	<FormControl
																		as="textarea"
																		row="3"
																		value={ this.state.newHost.description }
																		onChange={ this.handleChangeHosts }
																	/>
																</FormGroup>
															</Col>

															<Col sm={4} className="contestLabel text-muted"><Form.Label>Link</Form.Label></Col>
															<Col sm={8}>
																<FormGroup controlId={"currentContestHosts.new.link"}>
																	<FormControl
																		type="text"
																		value={ this.state.newHost.link }
																		onChange={ this.handleChangeHosts }
																	/>
																</FormGroup>
															</Col>

															<Col sm={4} className="contestLabel text-muted"><Form.Label>Image URL</Form.Label></Col>
															<Col sm={8}>
																<FormGroup controlId={"currentContestHosts.new.image_url"}>
																	<FormControl
																		type="text"
																		value={ this.state.newHost.image_url }
																		onChange={ this.handleChangeHosts }
																	/>
																</FormGroup>
															</Col>

															<Col sm={4}></Col>
															<Col sm={8}>
																<button className={"btn btn-primary" + (this.state.newHost.name ? "" : " disabled") } type="submit" onClick={ (e) => { this.handleHostSave(e) } }>Add { this.state.newHost.name }</button>
															</Col>

														</Row>
													</Col>
													<Col sm={4}>
														<div className="form-group">

															{this.state.newHostImage.image_preview ? (this.state.newHostImage.image_preview.map(file => (
																<div key={file.name}>
																	<img className="img-fluid mb-2 imagePreview" src={file.preview} alt="" />
																</div>
															))) : ""}
															{!(this.state.newHostImage.image_preview) && this.state.newHost.image_url ?
																<img className="img-fluid mb-2 imagePreview" src={ encodeURI(this.state.newHost.image_url) } alt="" />
															: ""}
															{ this.state.newHost.name ?
																<DragAndDropDialog callback={ this.dragAndDropFileChange }
																	prompt="Drag your host image here"
																	format="Supports: jpg, png"
																	dims="Max size: 2MB"
																	styles="dragAndDropDialog videoUploadTextInput"
																	multiple={false}
																	ddkey="host_image_url"
																	selectedFile={ this.state.newHostImage.file_name }/>
															: <i>Enter a Name to upload image.</i> }
														</div>
													</Col>
												</Row>
											</div>

										</Col>
									</Row>
								</Container>
							</Modal.Body>
						</Modal>
					: "" }

					{ this.state.showSponsorsModal ?
						<Modal centered show={true} size="lg" className="launchModal" onHide={this.showSponsorsModalCallback}>
							<Modal.Header closeButton className="border-bottom-0 p-0">
								<Modal.Title className="m-3 modalTitle highlight w-100">
									Add New Sponsor
								</Modal.Title>
							</Modal.Header>
							<Modal.Body className="p-0">
								<Container>
									<Row>
										<Col className="mx-2 pb-4 text-justify">

											<div className="SponsorContainer">
												<Row>
													<Col sm={8}>

														<Row>
															<Col sm={4} className="contestLabel text-muted"><Form.Label>Name</Form.Label></Col>
															<Col sm={8}>
																<FormGroup controlId={"currentContestSponsors.new.name"}>
																	<FormControl
																		type="text"
																		value={ this.state.newSponsor.name }
																		onChange={ this.handleChangeSponsors }
																	/>
																</FormGroup>
															</Col>

															<Col sm={4} className="contestLabel text-muted"><Form.Label>Tagline</Form.Label></Col>
															<Col sm={8}>
																<FormGroup controlId={"currentContestSponsors.new.tagline"}>
																	<FormControl
																		type="text"
																		value={ this.state.newSponsor.tagline }
																		onChange={ this.handleChangeSponsors }
																	/>
																</FormGroup>
															</Col>

															<Col sm={4} className="contestLabel text-muted"><Form.Label>Description</Form.Label></Col>
															<Col sm={8}>
																<FormGroup controlId={"currentContestSponsors.new.description"}>
																	<FormControl
																		as="textarea"
																		row="3"
																		value={ this.state.newSponsor.description }
																		onChange={ this.handleChangeSponsors }
																	/>
																</FormGroup>
															</Col>

															<Col sm={4} className="contestLabel text-muted"><Form.Label>Link</Form.Label></Col>
															<Col sm={8}>
																<FormGroup controlId={"currentContestSponsors.new.link"}>
																	<FormControl
																		type="text"
																		value={ this.state.newSponsor.link }
																		onChange={ this.handleChangeSponsors }
																	/>
																</FormGroup>
															</Col>

															<Col sm={4} className="contestLabel text-muted"><Form.Label>Image URL</Form.Label></Col>
															<Col sm={8}>
																<FormGroup controlId={"currentContestSponsors.new.image_url"}>
																	<FormControl
																		type="text"
																		value={ this.state.newSponsor.image_url }
																		onChange={ this.handleChangeSponsors }
																	/>
																</FormGroup>
															</Col>

															<Col sm={4}></Col>
															<Col sm={8}>
																<button className={"btn btn-primary" + (this.state.newSponsor.name ? "" : " disabled") } type="submit" onClick={ (e) => { this.handleSponsorsave(e) } }>Add { this.state.newSponsor.name }</button>
															</Col>

														</Row>
													</Col>
													<Col sm={4}>
														<div className="form-group">

															{this.state.newSponsorImage.image_preview ? (this.state.newSponsorImage.image_preview.map(file => (
																<div key={file.name}>
																	<img className="img-fluid mb-2 imagePreview" src={file.preview} alt="" />
																</div>
															))) : ""}
															{!(this.state.newSponsorImage.image_preview) && this.state.newSponsor.image_url ?
																<img className="img-fluid mb-2 imagePreview" src={ encodeURI(this.state.newSponsor.image_url) } alt="" />
															: ""}
															{ this.state.newSponsor.name ?
																<DragAndDropDialog callback={ this.dragAndDropFileChange }
																	prompt="Drag your Sponsor image here"
																	format="Supports: jpg, png"
																	dims="Max size: 2MB"
																	styles="dragAndDropDialog videoUploadTextInput"
																	multiple={false}
																	ddkey="Sponsor_image_url"
																	selectedFile={ this.state.newSponsorImage.file_name }/>
															: <i>Enter a Name to upload image.</i> }
														</div>
													</Col>
												</Row>
											</div>

										</Col>
									</Row>
								</Container>
							</Modal.Body>
						</Modal>
					: "" }

					{ this.state.showPrizesModal ?
						<Modal centered show={true} size="lg" className="launchModal" onHide={this.showPrizesModalCallback}>
							<Modal.Header closeButton className="border-bottom-0 p-0">
								<Modal.Title className="m-3 modalTitle highlight w-100">
									Add New Prize
								</Modal.Title>
							</Modal.Header>
							<Modal.Body className="p-0">
								<Container>
									<Row>
										<Col className="mx-2 pb-4 text-justify">

											<div className="PrizeContainer">
												<Row>
													<Col sm={8}>

														<Row>
															<Col sm={4} className="contestLabel text-muted"><Form.Label>Name</Form.Label></Col>
															<Col sm={8}>
																<FormGroup controlId={"currentContestPrizes.new.name"}>
																	<FormControl
																		type="text"
																		value={ this.state.newPrize.name }
																		onChange={ this.handleChangePrizes }
																	/>
																</FormGroup>
															</Col>

															<Col sm={4} className="contestLabel text-muted"><Form.Label>Tagline</Form.Label></Col>
															<Col sm={8}>
																<FormGroup controlId={"currentContestPrizes.new.tagline"}>
																	<FormControl
																		type="text"
																		value={ this.state.newPrize.tagline }
																		onChange={ this.handleChangePrizes }
																	/>
																</FormGroup>
															</Col>

															<Col sm={4} className="contestLabel text-muted"><Form.Label>Description</Form.Label></Col>
															<Col sm={8}>
																<FormGroup controlId={"currentContestPrizes.new.description"}>
																	<FormControl
																		as="textarea"
																		row="3"
																		value={ this.state.newPrize.description }
																		onChange={ this.handleChangePrizes }
																	/>
																</FormGroup>
															</Col>

															<Col sm={4} className="contestLabel text-muted"><Form.Label>Link</Form.Label></Col>
															<Col sm={8}>
																<FormGroup controlId={"currentContestPrizes.new.link"}>
																	<FormControl
																		type="text"
																		value={ this.state.newPrize.link }
																		onChange={ this.handleChangePrizes }
																	/>
																</FormGroup>
															</Col>

															<Col sm={4} className="contestLabel text-muted"><Form.Label>Image URL</Form.Label></Col>
															<Col sm={8}>
																<FormGroup controlId={"currentContestPrizes.new.image_url"}>
																	<FormControl
																		type="text"
																		value={ this.state.newPrize.image_url }
																		onChange={ this.handleChangePrizes }
																	/>
																</FormGroup>
															</Col>

															<Col sm={4}></Col>
															<Col sm={8}>
																<button className={"btn btn-primary" + (this.state.newPrize.name ? "" : " disabled") } type="submit" onClick={ (e) => { this.handlePrizesave(e) } }>Add { this.state.newPrize.name }</button>
															</Col>

														</Row>
													</Col>
													<Col sm={4}>
														<div className="form-group">

															{this.state.newPrizeImage.image_preview ? (this.state.newPrizeImage.image_preview.map(file => (
																<div key={file.name}>
																	<img className="img-fluid mb-2 imagePreview" src={file.preview} alt="" />
																</div>
															))) : ""}
															{!(this.state.newPrizeImage.image_preview) && this.state.newPrize.image_url ?
																<img className="img-fluid mb-2 imagePreview" src={ encodeURI(this.state.newPrize.image_url) } alt="" />
															: ""}
															{ this.state.newPrize.name ?
																<DragAndDropDialog callback={ this.dragAndDropFileChange }
																	prompt="Drag your Prize image here"
																	format="Supports: jpg, png"
																	dims="Max size: 2MB"
																	styles="dragAndDropDialog videoUploadTextInput"
																	multiple={false}
																	ddkey="Prize_image_url"
																	selectedFile={ this.state.newPrizeImage.file_name }/>
															: <i>Enter a Name to upload image.</i> }
														</div>
													</Col>
												</Row>
											</div>

										</Col>
									</Row>
								</Container>
							</Modal.Body>
						</Modal>
					: "" }




					<Row>
						<Col className="my-4">
							<h1 className="display-4">Contest Management</h1>
							<hr/>
						</Col>
					</Row>

					<Row>
						<Col sm={2}></Col>
						<Col sm={7}>
							<div id="toast" className={
									((this.state.toastSuccess === true) ? "bg-success" : (
										(this.state.toastSuccess === false) ? "bg-warning" : "bg-info"
									)) + " toastMessage my-3"
								} hidden={ this.state.toastMsg === "" }>

								{
									this.state.toastMsg
								}

							</div>
						</Col>
					</Row>

					{
						(this.state.showEditContestForm) ? (

							<Form>
								<Row className="mt-2 mb-5 contestNavbar">
									<Col sm={2}>
										<a href="/contest-management" className="btn btn-info btn-sm ml-3">Return to All Contests</a>
									</Col>
									<Col sm={4}>
										<h3 className="text-muted">
										{
											(this.state.editContestFormMode === "edit") ? (
												"Edit Contest"
											) : ( "Create Contest" )
										}
										</h3>
									</Col>
									<Col sm={6} className="text-right">
										<Button className="btn-lg btn-success m-2 px-5" type="submit" onClick={ (e) => { this.handleContestSave(e) } }>Save</Button>
										<Button className="btn-lg btn-danger m-2 px-5" type="submit" onClick={ (e) => { this.handleContestDelete(e) } }>Delete</Button>
									</Col>
								</Row>

								{(this.state.editContestFormMode === "edit") ?
								<Row className="mt-4 mb-4">
									<Col sm={1} className="contestLabel text-muted">Rounds</Col>
									<Col sm={11}>
										<Row>
											{this.state.currentContestRounds.length > 0 ?
												this.state.currentContestRounds.map((round, round_key) => (
													<Col md={6} key={round_key}>
														<div className="RoundContainer">
															<div><strong>Name:</strong> {round.name}</div>
															<div><strong>Description:</strong> {round.description}</div>
															<div><strong>End Date:</strong> {round.end_date}</div>
															<div><strong>Current:</strong> {round.is_current ? "Yes" : "No"}</div>
															<div><strong>Featured:</strong> {round.featured ? "Yes" : "No"}</div>
															{round.is_current ? <div className="my-2"><i>(You cannot edit the current round.)</i></div> : ""}
															{round.featured ?
																<Button className="btn-warning m-2" type="button" onClick={ (e) => { this.setFeaturedRound(e, round.id, "unset") } }>Unset as Featured</Button>
																:
																<Button className="btn-info m-2" type="button" onClick={ (e) => { this.setFeaturedRound(e, round.id) } }>Set as Featured</Button>
															}
															<Button className="btn-info m-2" type="button" onClick={ (e) => { window.location = `/contest-management/${this.state.currentContestId}/round/${round.id}` } }>View/Edit Round</Button>
														</div>
													</Col>
												))
											: <i>"No Current Rounds"</i> }
										</Row>
										<Row>
											<Col>
												<div>
													<Button className="mb-4" onClick={ () => { window.location = `/contest-management/${this.state.currentContestId}/round/create` } }>Create New Round</Button>
												</div>
											</Col>
										</Row>
									</Col>
								</Row>
								: ""}

								<Row>
									<Col md={6}>
										<Row>
											<Col sm={2} className="contestLabel text-muted">Name</Col>
											<Col sm={10}>
												<FormGroup controlId="currentContestName">
													<FormControl
														autoFocus
														type="text"
														value={ this.state.currentContestName }
														onChange={ this.handleChange }
													/>
												</FormGroup>
											</Col>

											<Col sm={2} className="contestLabel text-muted">Description</Col>
											<Col sm={10}>
												<FormGroup controlId="currentContestDescription">
													<FormControl
														as="textarea"
														rows="4"
														value={ this.state.currentContestDescription }
														onChange={ this.handleChange }
													/>
												</FormGroup>
											</Col>

											<Col sm={2} className="contestLabel text-muted">Tagline</Col>
											<Col sm={10}>
												<FormGroup controlId="currentContestTagline">
													<FormControl
														type="text"
														value={ this.state.currentContestTagline }
														onChange={ this.handleChange }
													/>
												</FormGroup>
											</Col>

											<Col sm={2} className="contestLabel text-muted">Max Video Length (seconds)</Col>
											<Col sm={10}>
												<FormGroup controlId="currentContestMaxLength">
													<FormControl
														type="text"
														value={ this.state.currentContestMaxLength }
														onChange={ this.handleChange }
													/>
												</FormGroup>
											</Col>

											<Col sm={2} className="contestLabel text-muted">Terms & Conditions</Col>
											<Col sm={10}>
												<FormGroup controlId="currentContestTerms">
													<FormControl
														as="textarea"
														rows="5"
														value={ this.state.currentContestTerms }
														onChange={ this.handleChange }
													/>
												</FormGroup>
											</Col>

											<Col sm={2} className="contestLabel text-muted">Extra Info</Col>
											<Col sm={10}>
												<FormGroup controlId="currentContestExtraInfo">
													<FormControl
														as="textarea"
														rows="5"
														value={ this.state.currentContestExtraInfo }
														onChange={ this.handleChange }
													/>
												</FormGroup>
											</Col>

											<Col sm={2} className="contestLabel text-muted">Start Date</Col>
											<Col sm={10} className="datePicker">
												<DatePicker id="start-date" selected={ Date.parse(this.state.currentContestStart) }
													onChange={ this.handleStartDateChange }/>
											</Col>

											<Col sm={2} className="contestLabel text-muted">End Date</Col>
											<Col sm={10} className="datePicker">
												<DatePicker id="end-date" selected={ Date.parse(this.state.currentContestEnd) }
													onChange={ this.handleEndDateChange }/>
											</Col>

											<Col sm={2} className="contestLabel text-muted">Visible?</Col>
											<Col sm={10}>
												<FormGroup controlId="currentContestVisible">
													<FormControl
														as="select"
														value={ this.state.currentContestVisible }
														onChange={ this.handleChange }
													>
														<option value={0}>No</option>
														<option value={1}>Yes</option>
													</FormControl>
												</FormGroup>
											</Col>

											<Col sm={2} className="contestLabel text-muted">Gradient Color</Col>
											<Col sm={5}>
												<FormGroup controlId="currentContestGradientStart">
													<BlockPicker
														color={ this.state.currentContestGradientStart }
														onChangeComplete={ this.handleChangeGradientStart }
														triangle="hide"
													/>
												</FormGroup>
											</Col>
											<Col sm={5}>
												<FormGroup controlId="currentContestGradientEnd">
													<BlockPicker
														color={ this.state.currentContestGradientEnd }
														onChangeComplete={ this.handleChangeGradientEnd }
														triangle="hide"
													/>
												</FormGroup>
											</Col>

										</Row>
									</Col>

									{this.state.editContestFormMode !== "create" ?
									<Col md={6}>
										<Row>
											<Col sm={2} className="contestLabel text-muted">Hero Image</Col>
											<Col sm={10}>
												<div className="form-group">
													{this.state.success ? <successMessage/> : null}

													{this.state.heroWebFilePreview ? (this.state.heroWebFilePreview.map(file => (
														<div key={file.name}>
															<img className="img-fluid mb-2 imagePreview" src={file.preview} alt="" />
														</div>
													))) :
														<img className="img-fluid mb-2 imagePreview" src={ encodeURI(this.state.currentContestHeroWeb) } alt="" />
													}
													<DragAndDropDialog callback={ this.dragAndDropFileChange }
														prompt="Drag your hero image (web) here"
														format="Supports: jpg, png"
														dims="Max size: 2MB"
														styles="dragAndDropDialog videoUploadTextInput"
														multiple={false}
														ddkey="hero_image_web"
														selectedFile={ this.state.heroWebFileName }/>
												</div>
												<div className="form-group">
													{this.state.heroPhoneFilePreview ? (this.state.heroPhoneFilePreview.map(file => (
														<div key={file.name}>
															<img className="img-fluid mb-2 imagePreview" src={file.preview} alt="" />
														</div>
													))) :
														<img className="img-fluid mb-2 imagePreview" src={ encodeURI(this.state.currentContestHeroPhone) } alt="" />
													}
													<DragAndDropDialog callback={ this.dragAndDropFileChange }
														prompt="Drag your hero image (phone) here"
														format="Supports: jpg, png"
														dims="Max size: 2MB"
														styles="dragAndDropDialog videoUploadTextInput"
														multiple={false}
														ddkey="hero_image_phone"
														selectedFile={ this.state.heroPhoneFileName }/>
												</div>
											</Col>
										</Row>
									</Col>
									: "" }

									{this.state.editContestFormMode !== "create" ? <>
									{/*Hosts Manager*/}
									<Col md={12} className="my-4">
										<Row>
											<Col sm={2} className="contestLabel text-muted">Hosts</Col>
											<Col sm={10}>
												{
													(this.state.loadingDone && this.state.currentContestData.hosts) ? (

														this.state.currentContestData.hosts.map((host, i) => {
															return (
																<div className="HostContainer" key={"host_"+i}>
																	<Row>
																		<Col sm={8}>
																			<Row>

																				<Col sm={4} className="contestLabel text-muted"><Form.Label>Name</Form.Label></Col>
																				<Col sm={8}>
																					<FormGroup controlId={"currentContestHosts."+i+".name"}>
																						<FormControl
																							autoFocus
																							type="text"
																							value={ host.name }
																							onChange={ this.handleChangeHosts }
																							disabled
																						/>
																					</FormGroup>
																				</Col>

																				<Col sm={4} className="contestLabel text-muted"><Form.Label>Tagline</Form.Label></Col>
																				<Col sm={8}>
																					<FormGroup controlId={"currentContestHosts."+i+".tagline"}>
																						<FormControl
																							type="text"
																							value={ host.tagline }
																							onChange={ this.handleChangeHosts }
																							disabled
																						/>
																					</FormGroup>
																				</Col>

																				<Col sm={4} className="contestLabel text-muted"><Form.Label>Description</Form.Label></Col>
																				<Col sm={8}>
																					<FormGroup controlId={"currentContestHosts."+i+".description"}>
																						<FormControl
																							as="textarea"
																							row="3"
																							value={ host.description }
																							onChange={ this.handleChangeHosts }
																							disabled
																						/>
																					</FormGroup>
																				</Col>

																				<Col sm={4} className="contestLabel text-muted"><Form.Label>Link</Form.Label></Col>
																				<Col sm={8}>
																					<FormGroup controlId={"currentContestHosts."+i+".link"}>
																						<FormControl
																							type="text"
																							value={ host.link }
																							onChange={ this.handleChangeHosts }
																							disabled
																						/>
																					</FormGroup>
																				</Col>

																				<Col sm={4} className="contestLabel text-muted"><Form.Label>Image URL</Form.Label></Col>
																				<Col sm={8}>
																					<FormGroup controlId={"currentContestHosts."+i+".image_url"}>
																						<FormControl
																							type="text"
																							value={ host.image_url }
																							onChange={ this.handleChangeHosts }
																							disabled
																						/>
																					</FormGroup>
																				</Col>

																				<Col sm={4}></Col>
																				<Col sm={8}>
																					<button className="btn btn-danger" onClick={ (e) => { this.handleHostDelete(e, host.id) } }>Delete { host.name }</button>
																				</Col>

																			</Row>
																		</Col>
																		<Col sm={4}>
																			<div className="form-group">

																				{this.state.success ? <successMessage/> : null}

																				{host.image_url_preview ? (host.image_url_preview.map(file => (
																					<div key={file.name}>
																						<img className="img-fluid mb-2 imagePreview" src={file.preview} alt="" />
																					</div>
																				))) :
																					<img className="img-fluid mb-2 imagePreview" src={ encodeURI(host.image_url) } alt="" />
																				}
																			</div>
																		</Col>
																	</Row>
																</div>
															)
														})
													) : (
														<Spinner animation="border" role="status">
															<span className="sr-only">Loading...</span>
														</Spinner>
													)
												}
												{this.state.loadingDone ?
													<Button onClick={ () => { this.setState({ showHostsModal: true }) } }>Add New Host</Button>
												: ""}
											</Col>
										</Row>
									</Col>

									{/*Sponsors Manager*/}
									<Col md={12} className="my-4">
										<Row>
											<Col sm={2} className="contestLabel text-muted">Sponsors</Col>
											<Col sm={10}>
												{
													(this.state.loadingDone && this.state.currentContestData.sponsors) ? (

														this.state.currentContestData.sponsors.map((sponsor, i) => {
															return (
																<div className="SponsorContainer" key={"sponsor_"+i}>
																	<Row>
																		<Col sm={8}>
																			<Row>

																				<Col sm={4} className="contestLabel text-muted"><Form.Label>Name</Form.Label></Col>
																				<Col sm={8}>
																					<FormGroup controlId={"currentContestSponsors."+i+".name"}>
																						<FormControl
																							autoFocus
																							type="text"
																							value={ sponsor.name || '' }
																							onChange={ this.handleChangeSponsors }
																							disabled
																						/>
																					</FormGroup>
																				</Col>

																				<Col sm={4} className="contestLabel text-muted"><Form.Label>Tagline</Form.Label></Col>
																				<Col sm={8}>
																					<FormGroup controlId={"currentContestSponsors."+i+".tagline"}>
																						<FormControl
																							type="text"
																							value={ sponsor.tagline || '' }
																							onChange={ this.handleChangeSponsors }
																							disabled
																						/>
																					</FormGroup>
																				</Col>

																				<Col sm={4} className="contestLabel text-muted"><Form.Label>Description</Form.Label></Col>
																				<Col sm={8}>
																					<FormGroup controlId={"currentContestSponsors."+i+".description"}>
																						<FormControl
																							as="textarea"
																							row="3"
																							value={ sponsor.description || '' }
																							onChange={ this.handleChangeSponsors }
																							disabled
																						/>
																					</FormGroup>
																				</Col>

																				<Col sm={4} className="contestLabel text-muted"><Form.Label>Link</Form.Label></Col>
																				<Col sm={8}>
																					<FormGroup controlId={"currentContestSponsors."+i+".link"}>
																						<FormControl
																							type="text"
																							value={ sponsor.link || '' }
																							onChange={ this.handleChangeSponsors }
																							disabled
																						/>
																					</FormGroup>
																				</Col>

																				<Col sm={4} className="contestLabel text-muted"><Form.Label>Image URL</Form.Label></Col>
																				<Col sm={8}>
																					<FormGroup controlId={"currentContestSponsors."+i+".image_url"}>
																						<FormControl
																							type="text"
																							value={ sponsor.image_url || '' }
																							onChange={ this.handleChangeSponsors }
																							disabled
																						/>
																					</FormGroup>
																				</Col>

																				<Col sm={4}></Col>
																				<Col sm={8}>
																					<button className="btn btn-danger" onClick={ (e) => { this.handleSponsorDelete(e, sponsor.id) } }>Delete { sponsor.name }</button>
																				</Col>

																			</Row>
																		</Col>
																		<Col sm={4}>
																			<div className="form-group">

																				{this.state.success ? <successMessage/> : null}

																				{sponsor.image_url_preview ? (sponsor.image_url_preview.map(file => (
																					<div key={file.name}>
																						<img className="img-fluid mb-2 imagePreview" src={file.preview} alt="" />
																					</div>
																				))) :
																					<img className="img-fluid mb-2 imagePreview" src={ encodeURI(sponsor.image_url) } alt="" />
																				}
																			</div>
																		</Col>
																	</Row>
																</div>
															)
														})
													) : (
														<Spinner animation="border" role="status">
															<span className="sr-only">Loading...</span>
														</Spinner>
													)
												}
												{this.state.loadingDone ?
													<Button onClick={ () => { this.setState({ showSponsorsModal: true }) } }>Add New Sponsor</Button>
												: ""}
											</Col>
										</Row>
									</Col>

									{/*Prizes Manager*/}
									<Col md={12} className="my-4">
										<Row>
											<Col sm={2} className="contestLabel text-muted">Prizes</Col>
											<Col sm={10}>
												{
													(this.state.loadingDone && this.state.currentContestData.prizes) ? (

														this.state.currentContestData.prizes.map((prize, i) => {
															return (
																<div className="PrizeContainer" key={"prize_"+i}>
																	<Row>
																		<Col sm={8}>
																			<Row>

																				<Col sm={4} className="contestLabel text-muted"><Form.Label>Name</Form.Label></Col>
																				<Col sm={8}>
																					<FormGroup controlId={"currentContestPrizes."+i+".name"}>
																						<FormControl
																							autoFocus
																							type="text"
																							value={ prize.name || '' }
																							onChange={ this.handleChangePrizes }
																							disabled
																						/>
																					</FormGroup>
																				</Col>

																				<Col sm={4} className="contestLabel text-muted"><Form.Label>Tagline</Form.Label></Col>
																				<Col sm={8}>
																					<FormGroup controlId={"currentContestPrizes."+i+".tagline"}>
																						<FormControl
																							type="text"
																							value={ prize.tagline || '' }
																							onChange={ this.handleChangePrizes }
																							disabled
																						/>
																					</FormGroup>
																				</Col>

																				<Col sm={4} className="contestLabel text-muted"><Form.Label>Description</Form.Label></Col>
																				<Col sm={8}>
																					<FormGroup controlId={"currentContestPrizes."+i+".description"}>
																						<FormControl
																							as="textarea"
																							row="3"
																							value={ prize.description || '' }
																							onChange={ this.handleChangePrizes }
																							disabled
																						/>
																					</FormGroup>
																				</Col>

																				<Col sm={4} className="contestLabel text-muted"><Form.Label>Link</Form.Label></Col>
																				<Col sm={8}>
																					<FormGroup controlId={"currentContestPrizes."+i+".link"}>
																						<FormControl
																							type="text"
																							value={ prize.link || '' }
																							onChange={ this.handleChangePrizes }
																							disabled
																						/>
																					</FormGroup>
																				</Col>

																				<Col sm={4} className="contestLabel text-muted"><Form.Label>Image URL</Form.Label></Col>
																				<Col sm={8}>
																					<FormGroup controlId={"currentContestPrizes."+i+".image_url"}>
																						<FormControl
																							type="text"
																							value={ prize.image_url || '' }
																							onChange={ this.handleChangePrizes }
																							disabled
																						/>
																					</FormGroup>
																				</Col>

																				<Col sm={4}></Col>
																				<Col sm={8}>
																					<button className="btn btn-danger" onClick={ (e) => { this.handlePrizeDelete(e, prize.id) } }>Delete { prize.name }</button>
																				</Col>

																			</Row>
																		</Col>
																		<Col sm={4}>
																			<div className="form-group">

																				{this.state.success ? <successMessage/> : null}

																				{prize.image_url_preview ? (prize.image_url_preview.map(file => (
																					<div key={file.name}>
																						<img className="img-fluid mb-2 imagePreview" src={file.preview} alt="" />
																					</div>
																				))) :
																					<img className="img-fluid mb-2 imagePreview" src={ encodeURI(prize.image_url) } alt="" />
																				}
																			</div>
																		</Col>
																	</Row>
																</div>
															)
														})
													) : (
														<Spinner animation="border" role="status">
															<span className="sr-only">Loading...</span>
														</Spinner>
													)
												}
												{this.state.loadingDone ?
													<Button onClick={ () => { this.setState({ showPrizesModal: true }) } }>Add New Prize</Button>
												: ""}
											</Col>
										</Row>
									</Col>
									</> : "" }

								</Row>

								<Row className="mt-5 mb-5 contestNavbar">
										<Col sm={6}>
										</Col>
										<Col sm={6} className="text-right">
											<Button className="btn-lg btn-success m-2 px-5" type="submit" onClick={ (e) => { this.handleContestSave(e) } }>Save</Button>
											<Button className="btn-lg btn-danger m-2 px-5" type="submit" onClick={ (e) => { this.handleContestDelete(e) } }>Delete</Button>
										</Col>
								</Row>

								<hr/>

							</Form>
						) : ( "" )
					}

					{
						(this.state.editContestFormMode !== "create") ?
							(this.state.showEditContestForm) ? (
								<Fragment>
									<Row>
										<Col sm={10}>
											<h3 className="text-muted">Video Entries for { this.state.currentContestName }</h3>
										</Col>
									</Row>

									{
										this.buildPageControlForm("Videos")
									}

									{
										(this.state.loadingDone && this.state.currentContestData.videos) ? (
											<Row>
												<Col>
													<Videos videoData={ this.state.currentContestData.videos }/>
												</Col>
											</Row>
										) : (
											<Spinner animation="border" role="status">
												<span className="sr-only">Loading...</span>
											</Spinner>
										)
									}
								</Fragment>
							) : ("")
						: ("")
					}

				</Container>
			);
		}

		renderUnauth() {
			return (
				<div className="mt-3">
					Please log in.
				</div>
			);
		}


		/*
			Generic change handler
		*/
		handleChange = event => {
			this.setState({
				[event.target.id]: event.target.value
			});
		};

		handleChangeGradientStart = (color) => {
			this.setState({ currentContestGradientStart: color.hex });
		};

		handleChangeGradientEnd = (color) => {
			this.setState({ currentContestGradientEnd: color.hex });
		};

		handleChangeHosts = event => {
			let newHost = this.state.newHost;
			let hostsData = this.state.currentHostsData;
			let currentHost = event.target.id.split(".");
			let hostId = currentHost[1];
			let hostField = currentHost[2];

			if (hostId !== 'new') {
				hostsData[hostId][hostField] = event.target.value;

				this.setState({
					currentHostsData: hostsData
				});
				console.log("currentHostsData", this.state.currentHostsData);
			} else {
				newHost[hostField] = event.target.value;
				this.setState({
					newHost: newHost
				});
				console.log("newHost", this.state.newHost);
			}
		};

		handleChangeSponsors = event => {
			let newSponsor = this.state.newSponsor;
			let sponsorsData = this.state.currentSponsorsData;
			let currentSponsor = event.target.id.split(".");
			let sponsorId = currentSponsor[1];
			let sponsorField = currentSponsor[2];

			if (sponsorId !== 'new') {
				sponsorsData[sponsorId][sponsorField] = event.target.value;

				this.setState({
					currentSponsorsData: sponsorsData
				});
				console.log("currentSponsorsData", this.state.currentSponsorsData);
			} else {
				newSponsor[sponsorField] = event.target.value;
				this.setState({
					newSponsor: newSponsor
				});
				console.log("newSponsor", this.state.newSponsor);
			}
		};

		handleChangePrizes = event => {
			let newPrize = this.state.newPrize;
			let prizesData = this.state.currentPrizesData;
			let currentPrize = event.target.id.split(".");
			let prizeId = currentPrize[1];
			let prizeField = currentPrize[2];

			if (prizeId !== 'new') {
				prizesData[prizeId][prizeField] = event.target.value;

				this.setState({
					currentPrizesData: prizesData
				});
				console.log("currentPrizesData", this.state.currentPrizesData);
			} else {
				newPrize[prizeField] = event.target.value;
				this.setState({
					newPrize: newPrize
				});
				console.log("newPrize", this.state.newPrize);
			}
		};

		/** Change handlers for date controls **/

		handleStartDateChange(value) {
			this.setState({ currentContestStart: value });
		}

		handleEndDateChange(value) {
			this.setState({ currentContestEnd: value });
		}

		/** Change handlers for paging controls **/

		handleResultsPerPageChange(e) {
			var newSize = parseInt(e.target.value);
			this.setState({ resultsPerPage: newSize });
			this.fetchNewPage(this.state.currentPage, newSize);
		}

		previousPage(e) {
			var newPage = Math.max(1, this.state.currentPage - 1);
			this.setState({ currentPage: newPage  });
			this.fetchNewPage(newPage, this.state.resultsPerPage);
		}

		nextPage(e) {
			var newPage = Math.min(this.state.maxPage, this.state.currentPage + 1);
			this.setState({ currentPage: newPage  });
			this.fetchNewPage(newPage, this.state.resultsPerPage);
		}

		/*
			Fetches a page of data (contest or videos) when a paging parameter changes.
		*/
		fetchNewPage(page, size) {
			// this flag indicates that a single contest is being viewed
			if(this.state.showEditContestForm) {
				this.getSingleContestData(this.state.currentContestId, page, size);
				this.getContestRoundsData(this.state.currentContestId);
			} else {
				this.getContestData(page, size);
			}
		}
}
