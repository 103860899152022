import config from "../config";
import { Auth } from "aws-amplify";
import axios from "axios";


export function getSingleUserLinks(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			const jwtToken = response.signInUserSession.idToken.jwtToken;
			axios.get(config.apiGateway.MODERATOR_URL + `/users/report/user/${params.sub}`, { headers: {Authorization: jwtToken} })
				.then(result => {
					//console.log("GET /users/report/user/{sub} success");
					//console.log(result);

					if (callback != null)
						callback(result.data.data);
				})
				.catch(error => {
					console.log("GET /users/report/user/{sub} error");
					console.log(error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function getSingleUser(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			const jwtToken = response.signInUserSession.idToken.jwtToken;
			axios.get(config.apiGateway.MODERATOR_URL + `/users/${params.sub}`, { headers: {Authorization: jwtToken} })
				.then(result => {
					//console.log("GET /users/{sub} success");
					//console.log(result);

					if (callback != null)
						callback(result.data.data);
				})
				.catch(error => {
					console.log("GET /users/{sub} error");
					console.log(error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function giveTokens(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			const jwtToken = response.signInUserSession.idToken.jwtToken;
			axios.put(config.apiGateway.MODERATOR_URL + `/users/${params.sub}/tokens/${params.amount}`, {}, { headers: {Authorization: jwtToken} })
				.then(result => {
					//console.log("PUT /users/${params.sub}/tokens/${params.amount} success");
					//console.log(result);

					if (callback != null)
						callback(true, result);
				})
				.catch(error => {
					console.log(`PUT /users/${params.sub}/tokens/${params.amount} error`);
					console.log(error);

					if (callback != null)
						callback(false, error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function blockUnblockUser(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.post(config.apiGateway.MODERATOR_URL + `/users/${params.sub}/${params.action}`, {}, { headers: {Authorization: jwtToken} })
				.then(result => {
					//console.log(`POST /users/${params.sub}/${params.action} success`);
					//console.log(result);

					if (callback != null)
						callback(true, result);
				})
				.catch(error => {
					console.log(`POST /users/${params.sub}/${params.action} error`);
					console.log(error);

					if (callback != null)
						callback(false, error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function getUsers(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			//console.log(response);
			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.get(config.apiGateway.MODERATOR_URL
					//+ `/users/report/digest?page=${params.page}&size=${params.pageSize}&start_date=${params.startDate}&end_date=${params.endDate}`
					+ `/users/digest?page=${params.page}&size=${params.pageSize}`
					, { headers: {Authorization: jwtToken} })
				.then(result => {
					// console.log("GET /users/report/digest success");
					// console.log(result);

					if (callback != null)
						callback(result.data.data);
				})
				.catch(error => {
					console.log("GET /users/report/digest error");
					console.log(error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}
