import React, { Component } from "react";
import { Container, Row, Col, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import "../css/Login.css";

import { Auth } from "aws-amplify";
import LoaderButton from "./LoaderButton";
// import FacebookButton from "./FacebookButton";
import ResetPassword from "./ResetPassword";

export default class Login extends Component {

    constructor(props) {
    	super(props);

    	this.state = {
  			isLoading: false,
  			email: "",
  			password: "",
			showResetPassword: false,
			resetPasswordMode: "reset" // default mode
		};

    }

	validateForm() {
  	     return this.state.email.length > 0 && this.state.password.length > 0;
	}

	handleChange = event => {
      	this.setState({
        	[event.target.id]: event.target.value
      	});
	}

	handleSubmit = async event => {
  		event.preventDefault();
  		this.setState({ isLoading: true });

  		try {
    		const user = await Auth.signIn(this.state.email, this.state.password);

			if(user.challengeName === "NEW_PASSWORD_REQUIRED") {

				this.setState({ resetPasswordMode: "new", showResetPassword: true, cognitoUser: user });

			} else {

				this.props.userHasAuthenticated(true);
				this.props.history.push("/contest-management");

			}
  		} catch (e) {
			console.log(e);
    		this.setState({ isLoading: false });
  		}
	}

    handleFbLogin = () => {
        this.props.userHasAuthenticated(true);
    };

	/*
		Display reset password component when user clicks forgot password link.
	*/
	handleResetPassword = event => {
		this.setState({ showResetPassword: true });
	}

  	render() {

    	return (
			<Container>
				<Row>
					<Col>
						<div className="Login" hidden={ this.state.showResetPassword }>
							<h1>Login</h1>
							<hr />
							<form onSubmit={this.handleSubmit}>
								{/*<FacebookButton
									onLogin={this.handleFbLogin}
								/>
								<hr />*/}
								<FormGroup controlId="email">
									<FormLabel>Email</FormLabel>
									<FormControl
										autoFocus
										type="email"
										value={this.state.email}
										onChange={this.handleChange}
									/>
								</FormGroup>
								<FormGroup controlId="password">
									<FormLabel>Password</FormLabel>
									<FormControl
										value={this.state.password}
										onChange={this.handleChange}
										type="password"
									/>
								</FormGroup>
								<LoaderButton
									block
									size="lg"
									disabled={!this.validateForm()}
									type="submit"
									isLoading={this.state.isLoading}
									text="Login"
									loadingText="Logging in…"
								/>
							</form>
							<div className="mt-3 text-muted text-center" onClick={ this.handleResetPassword }>
								Forgot password? Reset here
							</div>
						</div>
					</Col>
				</Row>
				{(this.state.showResetPassword) ? (
					<Row>
						<Col>
							<ResetPassword mode={ this.state.resetPasswordMode } cognitoUser={ this.state.cognitoUser } />
						</Col>
					</Row>
				) : ""}
			</Container>
    	);
  	}
}