import config from "../config";
import { Auth } from "aws-amplify";
import axios from "axios";

export function getSingleContest(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			// const jwtToken = response.signInUserSession.idToken.jwtToken;
			// axios.get(config.apiGateway.MODERATOR_URL + `/contests/${params.id}?page=${params.page}&size=${params.pageSize}`, { headers: {Authorization: jwtToken} })
			axios.get(config.apiGatewayFe.URL + `/contests/${params.id}`, { })
				.then(result => {
					console.log("GET /contests/{id} success");
					console.log(result);

					if (callback != null)
						callback(result.data.data);
				})
				.catch(error => {
					console.log("GET /contests/{id} error");
					console.log(error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function getContests(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			//console.log(response);
			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.get(config.apiGateway.MODERATOR_URL + `/contests?page=${params.page}&size=${params.pageSize}`, { headers: {Authorization: jwtToken} })
				.then(result => {
					console.log("GET /contests success");
					console.log(result);

					if (callback != null)
						callback(result.data.data);
				})
				.catch(error => {
					console.log("GET /contests error");
					console.log(error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function updateContest(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			//console.log(response);
			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.put(config.apiGateway.MODERATOR_URL + `/contests/${params.id}`, params.body, { headers: {Authorization: jwtToken} })
				.then(result => {
					console.log("PUT /contests success");
					console.log(result);

					if (callback != null)
						callback(true, result.data.data);
				})
				.catch(error => {
					console.log("PUT /contests error");
					console.log(error);

					if (callback != null)
						callback(false, error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function createContest(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			//console.log(response);
			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.post(config.apiGateway.MODERATOR_URL + `/contests`, params.body, { headers: {Authorization: jwtToken} })
				.then(result => {
					console.log("POST /contests success");
					console.log(result);

					if (callback != null)
						callback(true, result.data.data);
				})
				.catch(error => {
					console.log("POST /contests error");
					console.log(error);

					if (callback != null)
						callback(false, error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function deleteContest(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			//console.log(response);
			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.delete(config.apiGateway.MODERATOR_URL + `/contests/${params.id}`, { headers: {Authorization: jwtToken} })
				.then(result => {
					console.log("DELETE /contests success");
					console.log(result);

					if (callback != null)
						callback(true, result.data.data);
				})
				.catch(error => {
					console.log("DELETE /contests error");
					console.log(error);

					if (callback != null)
						callback(false, error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function createHost(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			//console.log(response);
			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.post(config.apiGateway.MODERATOR_URL + `/contests/${params.id}/host`, params.body, { headers: {Authorization: jwtToken} })
				.then(result => {
					console.log("POST /contests/{id}/host success");
					console.log(result);

					if (callback != null)
						callback(true, result.data.data);
				})
				.catch(error => {
					console.log("POST /contests/{id}/host error");
					console.log(error);

					if (callback != null)
						callback(false, error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function deleteHost(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			//console.log(response);
			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.delete(config.apiGateway.MODERATOR_URL + `/contests/host/${params.id}`, { headers: {Authorization: jwtToken} })
				.then(result => {
					console.log("DELETE /contests/host success");
					console.log(result);

					if (callback != null)
						callback(true, result.data.data);
				})
				.catch(error => {
					console.log("DELETE /contests/host error");
					console.log(error);

					if (callback != null)
						callback(false, error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function createSponsor(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			//console.log(response);
			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.post(config.apiGateway.MODERATOR_URL + `/contests/${params.id}/sponsor`, params.body, { headers: {Authorization: jwtToken} })
				.then(result => {
					console.log("POST /contests/{id}/sponsor success");
					console.log(result);

					if (callback != null)
						callback(true, result.data.data);
				})
				.catch(error => {
					console.log("POST /contests/{id}/sponsor error");
					console.log(error);

					if (callback != null)
						callback(false, error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function deleteSponsor(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			//console.log(response);
			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.delete(config.apiGateway.MODERATOR_URL + `/contests/sponsor/${params.id}`, { headers: {Authorization: jwtToken} })
				.then(result => {
					console.log("DELETE /contests/sponsor success");
					console.log(result);

					if (callback != null)
						callback(true, result.data.data);
				})
				.catch(error => {
					console.log("DELETE /contests/sponsor error");
					console.log(error);

					if (callback != null)
						callback(false, error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function createPrize(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			//console.log(response);
			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.post(config.apiGateway.MODERATOR_URL + `/contests/${params.id}/prize`, params.body, { headers: {Authorization: jwtToken} })
				.then(result => {
					console.log("POST /contests/{id}/prize success");
					console.log(result);

					if (callback != null)
						callback(true, result.data.data);
				})
				.catch(error => {
					console.log("POST /contests/{id}/prize error");
					console.log(error);

					if (callback != null)
						callback(false, error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function deletePrize(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			//console.log(response);
			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.delete(config.apiGateway.MODERATOR_URL + `/contests/prize/${params.id}`, { headers: {Authorization: jwtToken} })
				.then(result => {
					console.log("DELETE /contests/prize success");
					console.log(result);

					if (callback != null)
						callback(true, result.data.data);
				})
				.catch(error => {
					console.log("DELETE /contests/prize error");
					console.log(error);

					if (callback != null)
						callback(false, error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function getContestRounds(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			//console.log(response);
			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.get(config.apiGateway.MODERATOR_URL + `/contests/${params.id}/rounds`, { headers: {Authorization: jwtToken} })
				.then(result => {
					console.log("GET /contests/{id}/rounds success");
					console.log(result);

					if (callback != null)
						callback(result.data.data);
				})
				.catch(error => {
					console.log("GET /contests/{id}/rounds error");
					console.log(error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function getSingleContestRound(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			//console.log(response);
			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.get(config.apiGateway.MODERATOR_URL + `/contests/rounds/${params.id}`, { headers: {Authorization: jwtToken} })
				.then(result => {
					console.log("GET /contests/rounds/{id} success");
					console.log(result);

					if (callback != null)
						callback(result.data.data);
				})
				.catch(error => {
					console.log("GET /contests/rounds/{id} error");
					console.log(error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function updateContestRound(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			//console.log(response);
			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.put(config.apiGateway.MODERATOR_URL + `/contests/rounds/${params.id}`, params.body, { headers: {Authorization: jwtToken} })
				.then(result => {
					console.log("PUT /contests/rounds/{id} success");
					console.log(result);

					if (callback != null)
						callback(true, result.data.data);
				})
				.catch(error => {
					console.log("PUT /contests/rounds/{id} error");
					console.log(error);

					if (callback != null)
						callback(false, error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function createContestRound(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			//console.log(response);
			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.post(config.apiGateway.MODERATOR_URL + `/contests/${params.id}/round`, params.body, { headers: {Authorization: jwtToken} })
				.then(result => {
					console.log("POST /contests/{id}/round success");
					console.log(result);

					if (callback != null)
						callback(true, result.data.data);
				})
				.catch(error => {
					console.log("POST /contests/{id}/round error");
					console.log(error);

					if (callback != null)
						callback(false, error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function deleteContestRound(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			//console.log(response);
			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.delete(config.apiGateway.MODERATOR_URL + `/contests/rounds/${params.id}`, { headers: {Authorization: jwtToken} })
				.then(result => {
					console.log("DELETE /contests/rounds success");
					console.log(result);

					if (callback != null)
						callback(true, result.data.data);
				})
				.catch(error => {
					console.log("DELETE /contests/rounds error");
					console.log(error);

					if (callback != null)
						callback(false, error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function setContestRoundFeatured(callback, params) {
	try {
		Auth.currentAuthenticatedUser().then(response => {

			//console.log(response);
			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.put(config.apiGateway.MODERATOR_URL + `/contests/rounds/${params.id}/featured/toggle`, params.body, { headers: {Authorization: jwtToken} })
				.then(result => {
					console.log("PUT /contests/rounds/{id}/featured/toggle success");
					console.log(result);

					if (callback != null)
						callback(true, result.data.data);
				})
				.catch(error => {
					console.log("PUT /contests/rounds/{id}/featured/toggle error");
					console.log(error);

					if (callback != null)
						callback(false, error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}