// import Amplify, { Auth } from "aws-amplify";
import React, { Component, Fragment } from 'react';
import DatePicker from 'react-datepicker';
import { Container, Row, Col, Pagination, Form, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
// import { confirmAlert } from 'react-confirm-alert';
import { getUsers, giveTokens, getSingleUser, getSingleUserLinks, blockUnblockUser} from "../api/Users.js";
// import axios from "axios";
// import config from "../config";
import Videos from "../components/Videos.js";

import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-datepicker/dist/react-datepicker.css';


export default class UserReports extends Component {

	constructor(props) {
		super(props);

		const today = new Date();
		const lastMonth = new Date();
		lastMonth.setMonth(today.getMonth() - 1);

		this.state = {
			isAuthenticated: props.isAuthenticated,
			TEST_USER_ID: "306a3d96-28c0-4268-acbe-c3f733223bc8",
			isLoaded: false,
			showSingleUser: false,
			currentPage: 1,
			resultsPerPage: 10,
			maxPage: 99999,
			userReportsEndDate: today,
			userReportsStartDate: lastMonth,
			numTokensToGive: 0,
			showTokensInput: false,
			toastTime: 7000,
			toastMsg: "",
			toastSuccess: null
		}

		this.giveTokensCallback = this.giveTokensCallback.bind(this);
		this.getUsersCallback = this.getUsersCallback.bind(this);
		this.getSingleUserCallback = this.getSingleUserCallback.bind(this);
		this.getSingleUserLinksCallback = this.getSingleUserLinksCallback.bind(this);
		this.unblockUserCallback = this.unblockUserCallback.bind(this);
		this.blockUserCallback = this.blockUserCallback.bind(this);
		this.handleStartDateChange = this.handleStartDateChange.bind(this);
		this.handleEndDateChange = this.handleEndDateChange.bind(this);
	}


	componentDidMount() {
		this.getUsersDigest(this.state.currentPage, this.state.resultsPerPage);
	}


	getUsersCallback(data) {
		this.setState({ usersData: data, isLoaded: true, maxPage: (data.length > 0) ? this.state.maxPage : this.state.currentPage });
	}

	/*
		Validate paging controls input and make API call.
	*/
	getUsersDigest(page, size) {
		//console.log("page " + page);
		//console.log("size " + size);

		if(this.state.userReportsEndDate.getTime() <= this.state.userReportsStartDate.getTime()) {
			this.makeToast("End date must be later than start date", null);
			return;
		}

		getUsers(this.getUsersCallback, { page: page, pageSize: size
			, startDate: this.state.userReportsStartDate.toISOString().split("T")[0]
			, endDate: this.state.userReportsEndDate.toISOString().split("T")[0] });
	}


	componentWillUnmount() {

	}

	capitalizeFirstLetter(s) {
		if(typeof s === "string")
			return s.charAt(0).toUpperCase() + s.slice(1);
		else
			return "capitalizeFirstLetter: error formatting string";
	}

	callGetSingleUserLinks(sub) {
		getSingleUserLinks(this.getSingleUserLinksCallback, { sub: sub });
	}

	getSingleUserLinksCallback(response) {
		//console.log("getSingleUserLinksCallback");
		//console.log(response);

		const temp = this.state.singleUserData;

		response.videos.reverse().map((val, i) => {
			//console.log(val.id);
			//console.log(temp.videos[i].id);
			if(val.id === temp.videos[i].id)
				temp.videos[i].links = val.links;
			else
				console.log("mismatch id");
		} );

		this.setState({ singleUserData: temp, isLoaded: true, showSingleUser: true });

		//console.log(temp.videos);
	}

	/*
		Get single user data from the API.
	*/
	showUserDetails(sub) {
		this.setState({ isLoaded: false });
		getSingleUser(this.getSingleUserCallback, { sub: sub });
	}

	getSingleUserCallback(data) {
		//console.log('single user ');
		//console.log(data);

		if(data) {
			this.callGetSingleUserLinks(data.cognito_sub);
			this.setState({ singleUserData: data });
		}
	}


	callGiveTokens(sub) {
		if(this.state.showTokensInput === true) {
			const newAmount = parseInt(this.state.singleUserData.tokens) + parseInt(this.state.numTokensToGive);
			giveTokens(this.giveTokensCallback, { sub: sub, amount: newAmount});
			this.setState({ showTokensInput: false });
		} else
			this.setState({ showTokensInput: true });
	}

	giveTokensCallback(success, response) {
		//console.log(response);

		var msg = "";

		if(success === true)
			msg = "Tokens added to user account!";
		else if(success === false)
			msg = "Error giving tokens";

		this.makeToast(msg, success);
	}

	callBlockUser(sub) {
		blockUnblockUser(this.blockUserCallback, { sub: sub, action: "block" });
	}

	blockUserCallback(success, response) {
		//console.log(response);

		var msg;

		if(success === true)
			msg = "User blocked successfully!";
		else if(success === false)
			msg = "Error blocking user";

		this.makeToast(msg, success);
	}

	callUnblockUser(sub) {
		blockUnblockUser(this.unblockUserCallback, { sub: sub, action: "unblock" });
	}

	unblockUserCallback(success, response) {
		//console.log(response);

		var msg;

		if(success === true)
			msg = "User unblocked successfully!";
		else if(success === false)
			msg = "Error unblocking user";

		this.makeToast(msg, success);
	}

	render() {
		return (this.state.isAuthenticated === true) ? this.renderAuth() : this.renderUnauth();
	}

	renderUnauth() {
		return (
			<div className="text-muted">Please log in.</div>
		);
	}

	renderAuth() {
		return (
			<Fragment>
				<Container className="mb-3">
					<Row>
						<Col>
							<h1 className="display-4">User Reports</h1>
							<hr/>
						</Col>
					</Row>

					<div id="toast" className={
							((this.state.toastSuccess === true) ? "bg-success" : (
								(this.state.toastSuccess === false) ? "bg-warning" : "bg-info"
							)) + " toastMessage my-3"
						} hidden={ this.state.toastMsg === "" }>

						{
							this.state.toastMsg
						}

					</div>

					{
						this.buildUserReportsControls()
					}

					{

						(this.state.isLoaded) ? (

							(this.state.showSingleUser) ? (
								<Fragment>
									<Row>
										<Col sm={8}></Col>
										<Col sm={4}>
											<button type="button" className="btn-sm btn-primary my-3"
												onClick={ () => { this.setState({ showSingleUser: false }) } }>Back to user reports</button>
										</Col>
									</Row>

									{
										this.buildSingleUserDetails()
									}

								</Fragment>

							) : (
								<Fragment>
									{/*<Row>
										<Col>
											<button className="btn-sm btn-primary m-1" onClick={ () => { this.showUserDetails(this.state.TEST_USER_ID) } }>Show Test User</button>
										</Col>
									</Row>*/}
									<Row>
										<Col sm={1}></Col>
										<Col>
											<Row>
											{
												this.buildUserCards()
											}
											</Row>
										</Col>
									</Row>
								</Fragment>
							)
						) : (
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner>
						)
					}
				</Container>
			</Fragment>
		);
	}

	buildUserBadges(data) {
		//console.log('buildUserBadges');
		//console.log(data);

		const labelWidth = 3;
		const dataWidth = 9;

		return data.map((val, i) => {
			return (
				<Col sm={5} className="userBadge mx-1 my-3 p-2">
					<Row>
						<Col sm={labelWidth} className="text-muted userLabel">ID</Col>
						<Col sm={dataWidth}>{ val.badge_id }</Col>
					</Row>
					<Row>
						<Col sm={labelWidth} className="text-muted userLabel">Image</Col>
						<Col sm={dataWidth}>
							<img src={ val.badge_img } alt="badge"/>
						</Col>
					</Row>
					<Row>
						<Col sm={labelWidth} className="text-muted userLabel">Name</Col>
						<Col sm={dataWidth}>{ val.badge_name }</Col>
					</Row>
					<Row>
						<Col sm={labelWidth} className="text-muted userLabel">Description</Col>
						<Col sm={dataWidth}>{ val.badge_description }</Col>
					</Row>
					<Row>
						<Col sm={labelWidth} className="text-muted userLabel">Created</Col>
						<Col sm={dataWidth}>{ val.created_at }</Col>
					</Row>
				</Col>
			);
		});
	}

	buildUserGifts(data, type) {
		//console.log('buildUserGifts ' + type);
		//console.log(data);

		return data.map((val, i) => {

		});
	}

	buildUserUnmodVideos(data) {
		//console.log('buildUserUnmodVideos ');
		//console.log(data);

		return data.map((val, i) => {

		});
	}

	buildUserVideos(data) {
		//console.log('buildUserVideos ');
		//console.log(data);

		return (
			<Videos videoData={ data }/>
		);
	}

	buildSingleUserDetails() {
		const data = this.state.singleUserData;
		console.log("buildSingleUserDetails", data);

		const labelWidth = 3;
		const dataWidth = 8;

		const badges = this.buildUserBadges(data.badges);
		const giftsGiven = this.buildUserGifts(data.gifts_given, "given");
		const giftsReceived = this.buildUserGifts(data.gifts_received, "received");
		const unmoderatedVideos = this.buildUserUnmodVideos(data.unmoderated_videos);
		const videos = this.buildUserVideos(data.videos);

		return (
			<Fragment>
				<Row className="userDetailsContainer">
					<Col sm={1}></Col>
					<Col sm={11}>
						<Row>
							<Col><h4>Details for { data.display_name } ({ data.given_name } { data.family_name })</h4></Col>
						</Row>
						<Row>
							<Col sm={labelWidth} className="userLabel text-muted">Cognito ID</Col>
							<Col sm={dataWidth}>{ data.cognito_sub }</Col>
						</Row>
						<Row>
							<Col sm={labelWidth} className="userLabel text-muted">Display Name</Col>
							<Col sm={dataWidth}>{ data.display_name }</Col>
						</Row>
						<Row>
							<Col sm={labelWidth} className="userLabel text-muted">Given Name</Col>
							<Col sm={dataWidth}>{ data.given_name }</Col>
						</Row>
						<Row>
							<Col sm={labelWidth} className="userLabel text-muted">Family Name</Col>
							<Col sm={dataWidth}>{ data.family_name }</Col>
						</Row>
						<Row>
							<Col sm={labelWidth} className="userLabel text-muted">Email</Col>
							<Col sm={dataWidth}>{ data.email }</Col>
						</Row>
						<Row>
							<Col sm={labelWidth} className="userLabel text-muted">Email Verified</Col>
							<Col sm={dataWidth}>{ (data.email_verified) ? "Verified" : "No" }</Col>
						</Row>
						<Row>
							<Col sm={labelWidth} className="userLabel text-muted">Phone Number</Col>
							<Col sm={dataWidth}>{ data.phone_number }</Col>
						</Row>
						<Row>
							<Col sm={labelWidth} className="userLabel text-muted">Phone Verified</Col>
							<Col sm={dataWidth}>{ (data.phone_number_verified) ? "Verified" : "No" }</Col>
						</Row>
						<Row>
							<Col sm={labelWidth} className="userLabel text-muted">User Status</Col>
							<Col sm={dataWidth}>{ data.cognito_user_status }</Col>
						</Row>
						<Row>
							<Col sm={labelWidth} className="userLabel text-muted">Avatar</Col>
							<Col sm={dataWidth}>
								<img src={ data.avatar } className="img-fluid" alt="user avatar"/>
							</Col>
						</Row>
						<Row>
							<Col sm={labelWidth} className="userLabel text-muted">Profile Image</Col>
							<Col sm={dataWidth}>
								<img src={ data.profile_image } alt="user profile"/>
							</Col>
						</Row>
						<Row>
							<Col sm={labelWidth} className="userLabel text-muted">Cover Image</Col>
							<Col sm={dataWidth}>
								<img src={ data.cover_image } alt="user cover"/>
							</Col>
						</Row>
						<Row>
							<Col sm={labelWidth} className="userLabel text-muted">Postal Code</Col>
							<Col sm={dataWidth}>{ data.postal_code }</Col>
						</Row>
						<Row>
							<Col sm={labelWidth} className="userLabel text-muted">Bio</Col>
							<Col sm={dataWidth}>{ data.bio }</Col>
						</Row>
						<Row>
							<Col sm={labelWidth} className="userLabel text-muted">Birthdate</Col>
							<Col sm={dataWidth}>{ data.birthdate }</Col>
						</Row>
						<Row>
							<Col sm={labelWidth} className="userLabel text-muted">Tokens</Col>
							<Col sm={dataWidth}>{ data.tokens }</Col>
						</Row>
						<Row>
							<Col sm={labelWidth} className="userLabel text-muted">Notifications</Col>
							<Col sm={dataWidth}>{ data.notifications_count }</Col>
						</Row>
					</Col>
				</Row>

				{
					this.buildUserAdminControls()
				}

				{
					(badges.length > 0) ? (
						<Fragment>
							<Row>
								<Col sm={2}></Col>
								<Col className="text-left"><h4 className="mt-4">Badges</h4></Col>
							</Row>
							<Row>
								<Col sm={2}></Col>
								<Col>
									<Row>
										{ badges }
									</Row>
								</Col>
							</Row>
						</Fragment>
					) : ( "" )
				}

				{
					(giftsGiven.length > 0) ? (
						<Fragment>
							<Row>
								<Col sm={2}></Col>
								<Col sm={6}><h4 className="mt-4">Gifts Given</h4></Col>
							</Row>
							<Row>
								<Col sm={2}></Col>
								<Col>
									<Row>
										{ giftsGiven }
									</Row>
								</Col>
							</Row>
						</Fragment>
					) : ( "" )
				}

				{
					(giftsReceived.length > 0) ? (
						<Fragment>
							<Row>
								<Col sm={2}></Col>
								<Col sm={6}><h4 className="mt-4">Gifts Received</h4></Col>
							</Row>
							<Row>
								<Col sm={2}></Col>
								<Col>
									<Row>
										{ giftsReceived }
									</Row>
								</Col>
							</Row>
						</Fragment>
					) : ( "" )
				}

				{
					(unmoderatedVideos.length > 0) ? (
						<Fragment>
							<Row>
								<Col sm={2}></Col>
								<Col sm={6}><h4 className="mt-4">Unmoderated Videos</h4></Col>
							</Row>
							<Row>
								<Col sm={2}></Col>
								<Col>
									{ unmoderatedVideos }
								</Col>
							</Row>
						</Fragment>
					) : ( "" )
				}

				{
					(data.videos.length > 0) ? (
						<Fragment>
							<Row>
								<Col sm={2}></Col>
								<Col sm={6}><h4 className="mt-4">Approved Videos</h4></Col>
							</Row>
							<Row>
								<Col sm={2}></Col>
								<Col>
									{ videos }
								</Col>
							</Row>
						</Fragment>
					) : ( "" )
				}

			</Fragment>
		);
	}

	/*
		Builds the display component for individual users part of the users digest report.
	*/
	buildUserCards() {
		return this.state.usersData.map((val, i) => {

			const tooltip = (
				<Tooltip>
					Click to see more details
				</Tooltip>
			);

			return (
				<OverlayTrigger placement="top" overlay={ tooltip } key={"user_"+i}>
					<Col sm={5} className="userCard m-2" onClick={ () => this.showUserDetails(val.cognito_sub) }>
						{
							(val.avatar) ? (
								<Row>
									<Col sm={4} className="text-muted px-2 py-1">Avatar</Col>
									<Col sm={8} className="py-1">
										<img src={ val.avatar } alt="user avatar" className="img-fluid" />
									</Col>
								</Row>
							) : ( "" )
						}
						<Row>
							<Col sm={4} className="text-muted px-2 py-1">Cognito ID</Col>
							<Col sm={8} className="py-1">{ val.cognito_sub }</Col>
						</Row>
						<Row>
							<Col sm={4} className="text-muted px-2 py-1">Display Name</Col>
							<Col sm={8} className="py-1">{ val.display_name }</Col>
						</Row>
						<Row>
							<Col sm={4} className="text-muted px-2 py-1">Given Name</Col>
							<Col sm={8} className="py-1">{ val.given_name }</Col>
						</Row>
						<Row>
							<Col sm={4} className="text-muted px-2 py-1">Family Name</Col>
							<Col sm={8} className="py-1">{ val.family_name }</Col>
						</Row>
						<Row>
							<Col sm={4} className="text-muted px-2 py-1">Email</Col>
							<Col sm={8} className="py-1">{ val.email}</Col>
						</Row>
						<Row>
							<Col sm={4} className="text-muted px-2 py-1">Phone Number</Col>
							<Col sm={8} className="py-1">{ val.phone_number }</Col>
						</Row>
						<Row>
							<Col sm={4} className="text-muted px-2 py-1">Created</Col>
							<Col sm={8} className="py-1">{ val.created_at.split(" ")[0] }</Col>
						</Row>
						<Row>
							<Col sm={4} className="text-muted px-2 py-1">Video Count</Col>
							<Col sm={8} className="py-1">{ val.video_count }</Col>
						</Row>
					</Col>
				</OverlayTrigger>
			);
		});
	}

	/*
		Builds the admin control buttons for a single user.
	*/
	buildUserAdminControls() {
		const userCognitoSub = this.state.singleUserData.cognito_sub;

		return (
			<Form className="my-3">
				<Form.Group>
					<Row>
						<Col sm={3}>
							<h5 className="text-muted mt-1">Admin Controls</h5>
						</Col>
						<Col sm={3}>
							<button type="button" className="btn-lg btn-primary"
									onClick={ () => { this.callGiveTokens(userCognitoSub) } }>Give Tokens</button>
								{
									(this.state.showTokensInput) ? (
										<Fragment>
											<Form.Label className="text-muted my-1">Enter number of tokens to give and press button again</Form.Label>
											<Form.Control type="text" className="m-2" placeholder="# of tokens" value={ this.state.numTokensToGive }
													onChange={ (e) => { this.setState({ numTokensToGive: e.target.value }) } }/>
										</Fragment>
									) : ""
								}
						</Col>
						<Col sm={2}>
							<button type="button" className="btn-lg btn-warning"
								onClick={ () => { this.callBlockUser(userCognitoSub) } }>Block User</button>
						</Col>
						<Col sm={2}>
							<button type="button" className="btn-lg btn-warning"
								onClick={ () => { this.callUnblockUser(userCognitoSub) } }>Unblock User</button>
						</Col>
					</Row>
				</Form.Group>
			</Form>
		);
	}

	/*
		Builds the reports paging controls including dates.
	*/
	buildUserReportsControls() {
		return (
			<Form className="mt-3">
				<Form.Group>
					<Row>
						<Col sm={1}></Col>
						<Col sm={2}>
							<Row>
								<Col sm={2}>
									<Pagination>
										<Pagination.Prev onClick={ () => { this.previousPage() } }/>

										<Pagination.Item>
										{
											this.state.currentPage
										}
										</Pagination.Item>

										<Pagination.Next onClick={ () => { this.nextPage() } }/>
									</Pagination>
								</Col>
							</Row>
						</Col>
						<Col sm={5}>
							<Row>
								<Col sm={7}>
									<Form.Label className="text-muted">Users per page</Form.Label>
								</Col>
								<Col sm={3}>
									<Form.Control as="select" value={ this.state.resultsPerPage } onChange={ (e) => { this.handleResultsPerPageChange(e) } }>
										<option value="10">10</option>
										<option value="25">25</option>
										<option value="50">50</option>
									</Form.Control>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col sm={2} className="contestLabel text-muted pt-2">Start Date</Col>
						<Col sm={2} className="datePicker">
							<DatePicker id="start-date" selected={ Date.parse(this.state.userReportsStartDate) }
								onChange={ this.handleStartDateChange }/>
						</Col>
						<Col sm={2} className="contestLabel text-muted pt-2">End Date</Col>
						<Col sm={4} className="datePicker">
							<DatePicker id="end-date" selected={ Date.parse(this.state.userReportsEndDate) }
								onChange={ this.handleEndDateChange }/>
						</Col>
					</Row>
				</Form.Group>
				<hr/>
			</Form>
		);
	}


	/*
		Display a feedback message to the user
	*/
	makeToast(msg, success) {

		this.setState({ toastMsg: msg, toastSuccess: success });
		document.getElementById("toast").scrollIntoView();

		//clear toast after certain amount of time
		setTimeout(() => {
			this.setState({ toastMsg: "", toastSuccess: null });
		}, this.state.toastTime);
	}


	/** Change handlers for date controls **/

	handleStartDateChange(value) {
		this.setState({ userReportsStartDate: value });
	}

	handleEndDateChange(value) {
		this.setState({ userReportsEndDate: value });
	}


	/** Change handlers for paging controls **/

	handleResultsPerPageChange(e) {
		var newSize = parseInt(e.target.value);
		this.setState({ resultsPerPage: newSize });
		this.getUsersDigest(this.state.currentPage, newSize);
	}

	previousPage(e) {
		var newPage = Math.max(1, this.state.currentPage - 1);
		this.setState({ currentPage: newPage  });
		this.getUsersDigest(newPage, this.state.resultsPerPage);
	}

	nextPage(e) {
		var newPage = Math.min(this.state.maxPage, this.state.currentPage + 1);
		this.setState({ currentPage: newPage  });
		this.getUsersDigest(newPage, this.state.resultsPerPage);
	}
}