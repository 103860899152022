import config from "../config";
import { Auth } from "aws-amplify";
import axios from "axios";
// import VhxApi from '@vhx/vhxjs';


export function getVideos(callBack) {

	try {
		Auth.currentAuthenticatedUser().then(response => {
			console.log(response);

			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.get(config.apiGateway.MODERATOR_URL + '/moderator/videos?page=1&size=5', { headers: {Authorization: jwtToken} })
				.then(result => {
					console.log("GET /moderator/videos success");
					console.log(result);

					if (callBack != null)
						callBack(result.data.data);
				})
				.catch(error => {
					console.log("GET /moderator/videos error");
					console.log(error);
				})
		});
	} catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function approveVideo(uuid, callBack) {

	console.log("approveVideo");
	console.log(uuid);

	try {
		Auth.currentAuthenticatedUser().then(response => {
			console.log(response);

			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.post(config.apiGateway.MODERATOR_URL + '/moderator/videos/' + uuid + '/approve',
				{},
				{ headers: { Authorization: jwtToken }
				})
				.then(result => {
					console.log("POST /moderator/videos/{uuid}/approve success");
					console.log(result);

					if (callBack != null)
						callBack(result);
				})
				.catch(error => {
					console.log("POST /moderator/videos/{uuid}/approve error");
					console.log(error);
				})
		});
	}

	catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}

}

export function blockVideo(callBack, props) {
	console.log("blockVideo", props.id);

	try {
		Auth.currentAuthenticatedUser().then(response => {
			console.log(response);

			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.post(config.apiGateway.MODERATOR_URL + '/moderator/videos/block/' + props.id,
				{},
				{ headers: { Authorization: jwtToken }
				})
				.then(result => {
					console.log("POST /moderator/videos/block/{id} success");
					console.log(result);

					if (callBack != null)
						callBack(result);
				})
				.catch(error => {
					console.log("POST /moderator/videos/block/{id} error");
					console.log(error);
				})
		});
	}

	catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export function unblockVideo(callBack, props) {
	console.log("unblockVideo", props.id);

	try {
		Auth.currentAuthenticatedUser().then(response => {
			console.log(response);

			const jwtToken = response.signInUserSession.idToken.jwtToken;

			axios.post(config.apiGateway.MODERATOR_URL + '/moderator/videos/unblock/' + props.id,
				{},
				{ headers: { Authorization: jwtToken }
				})
				.then(result => {
					console.log("POST /moderator/videos/unblock/{id} success");
					console.log(result);

					if (callBack != null)
						callBack(result);
				})
				.catch(error => {
					console.log("POST /moderator/videos/unblock/{id} error");
					console.log(error);
				})
		});
	}

	catch(e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}