import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Login from "./components/Login";
import VideoModerator from "./components/VideoModerator";
import NotFound from './components/NotFound';
import ResetPassword from './components/ResetPassword';
import ContestManagement from './containers/ContestManagement';
import ContestEditor from './containers/ContestEditor';
import RoundEditor from './containers/RoundEditor';
import UserReports from './containers/UserReports';

export default ({ childProps }) =>
  	<Switch>
	    <AppliedRoute path="/" exact component={Login} props={childProps} />
	    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
		<UnauthenticatedRoute path="/reset" exact component={ResetPassword} props={childProps} />
	    <AuthenticatedRoute path="/video-management" exact component={VideoModerator} props={childProps} />
	    <AuthenticatedRoute path="/contest-management" exact component={ContestManagement} props={childProps} />
	    <AuthenticatedRoute path="/contest-management/:id" exact component={ContestEditor} props={childProps} />
	    <AuthenticatedRoute path="/contest-management/:id/round/:round" exact component={RoundEditor} props={childProps} />
	    <AuthenticatedRoute path="/user-management" exact component={UserReports} props={childProps} />
	    { /* Finally, catch all unmatched routes */ }
	    <Route component={NotFound} />
  	</Switch>;