import React from "react";
import { Button } from "react-bootstrap";
import "../css/LoaderButton.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export default ({
    isLoading,
    text,
    loadingText,
    className = "",
    disabled = false,
    ...props
}) =>
    <Button
        className={`LoaderButton ${className}`}
        disabled={disabled || isLoading}
        {...props}
    >
        {isLoading && <FontAwesomeIcon icon={faSpinner} />}&nbsp;
        {!isLoading ? text : loadingText}
    </Button>;
