import React, {  } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
// import { LinkContainer } from 'react-router-bootstrap';
// import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.css';
import './bootswatch/bootstrap.min.css';
// import './bootswatch/bootstrap.css';
import './App.css';

import Routes from "./Routes";
import ReactQueryParams from 'react-query-params';
import Amplify, { Auth } from "aws-amplify";

import config from "./config";


/*********************************
* Amplify Configuration
*********************************/
Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: config.cognito.REGION,
		userPoolId: config.cognito.USER_POOL_ID,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
		userPoolWebClientId: config.cognito.APP_CLIENT_ID
	}
});

class App extends ReactQueryParams {

	constructor(props) {
		super(props);

		this.state = {
			isAuthenticated: false,
			isAuthenticating: true,
			showProgress: false
		};
	}

	async componentDidMount() {
		try {
			await Auth.currentAuthenticatedUser()
				.then(user => {
					//console.log(user);
					this.userHasAuthenticated(true);
				})
				.catch(err => console.log(err));
		}
		catch(e) {
			if (e !== 'No current user') {
				console.log(e);
			}
		}

		this.setState({ isAuthenticating: false });
	}

	userHasAuthenticated = authenticated => {
		this.setState({ isAuthenticated: authenticated });
	}

	handleLogout = async event => {
		await Auth.signOut();

		this.userHasAuthenticated(false);

		// this.props.history.push("/");
	}

	render() {

		const childProps = {
			isAuthenticated: this.state.isAuthenticated,
			userHasAuthenticated: this.userHasAuthenticated
		};

		console.log(childProps);

		return (
			<div className="App">

				<Navbar bg="dark" variant="dark" expand="lg" fixed="top" sticky="top">
					{this.state.isAuthenticated ?
						<Navbar.Brand href="/contest-management">CelebYou Managers</Navbar.Brand>
					:
						<Navbar.Brand href="/">CelebYou Managers</Navbar.Brand>
					}
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					{this.state.isAuthenticated ?
						<Navbar.Collapse id="basic-navbar-nav">
							<Nav className="mr-auto">
								<Nav.Link href="/contest-management">Contest Management</Nav.Link>
								<Nav.Link href="/video-management">Review Videos</Nav.Link>
								<Nav.Link href="/user-management">User Reports</Nav.Link>
								<Nav.Link onClick={this.handleLogout}>Logout</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					: ""}
				</Navbar>

				<Routes childProps={childProps} />

			</div>
		);
	}

}

export default App;
