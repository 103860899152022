import React, { Component } from 'react';
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Player, ControlBar } from 'video-react';
import 'video-react/dist/video-react.css';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { blockVideo, unblockVideo } from "../api/Videos.js";

export default class Videos extends Component {

	constructor(props) {
		super(props);

		this.state = {
			videoData: props.videoData,
			showVideoPlayer: new Array(props.videoData.length).fill(false),
			videoLinkToUse: 2
		};

		this.blockVideoCallback = this.blockVideoCallback.bind(this);
	}

	componentDidMount() {
		// reset video players to thumbnails
		this.setState({ showVideoPlayer: new Array(this.state.showVideoPlayer.length).fill(false) });
	}

	showVideoPlayer(e) {
		const i = parseInt(e.target.alt);
		const temp = this.state.showVideoPlayer;
		temp[i] = true;
		this.setState({ showVideoPlayer: temp });
	}

	handleBlockVideo(event, videoId) {
		event.preventDefault();

		if(videoId) {
			confirmAlert({
				title: 'Confirm',
				message: 'Are you sure want to block this video?',
				buttons: [
					{
						label: 'Yes',
						onClick: () => blockVideo(this.blockVideoCallback, { id: videoId })
					},
					{
						label: 'No',
						onClick: () => { }
					}
				]
			});
		} else {
			confirmAlert({
				title: 'Error',
				message: 'No video selected.',
				buttons: [
					{
						label: 'OK',
						onClick: () => { }
					}
				]
			});
		}
	}

	blockVideoCallback(response, message) {
		// this.setState({ showEditContestForm: false });

		if(response) {
			// console.log(response);
			confirmAlert({
				title: 'Success',
				message: 'Video blocked!',
				buttons: [
					{
						label: 'OK',
						onClick: () => { }
					}
				]
			});
			// this.setState({ showHostsModal: false });
			// this.refreshContests();
		} else {
			confirmAlert({
				title: 'Error',
				message: 'Error blocking video... ' + message,
				buttons: [
					{
						label: 'OK',
						onClick: () => { }
					}
				]
			});
			// this.setState({ showEditContestForm: true });
		}
	}

	handleUnblockVideo(event, videoId) {
		event.preventDefault();

		if(videoId) {
			confirmAlert({
				title: 'Confirm',
				message: 'Are you sure want to unblock this video?',
				buttons: [
					{
						label: 'Yes',
						onClick: () => unblockVideo(this.unblockVideoCallback, { id: videoId })
					},
					{
						label: 'No',
						onClick: () => { }
					}
				]
			});
		} else {
			confirmAlert({
				title: 'Error',
				message: 'No video selected.',
				buttons: [
					{
						label: 'OK',
						onClick: () => { }
					}
				]
			});
		}
	}

	unblockVideoCallback(response, message) {
		// this.setState({ showEditContestForm: false });

		if(response) {
			// console.log(response);
			confirmAlert({
				title: 'Success',
				message: 'Video unblocked!',
				buttons: [
					{
						label: 'OK',
						onClick: () => { }
					}
				]
			});
			// this.setState({ showHostsModal: false });
			// this.refreshContests();
		} else {
			confirmAlert({
				title: 'Error',
				message: 'Error unblocking video... ' + message,
				buttons: [
					{
						label: 'OK',
						onClick: () => { }
					}
				]
			});
			// this.setState({ showEditContestForm: true });
		}
	}

	/*
		Build the row-spanning video cards with previously loaded video data.
	*/
	buildVideoRows() {

		const tooltip = (
			<Tooltip>
				<span>Click to watch video</span>
			</Tooltip>
		);

		return (this.state.videoData) ? this.state.videoData.map((val, i) => {
			return (
				<Row className="my-4 py-4 videoRow" key={ val.id }>
					<Col>
						<Row>
							<Col>
								<h4 className="pb-2">{ val.title }</h4>
							</Col>
						</Row>
						<Row className="my-1">
							<Col sm={3} className="text-muted">Description</Col>
							<Col>
								{ val.description }
							</Col>
						</Row>

						<Row className="my-1">
							<Col sm={3} className="text-muted">Vimeo Thumbnail</Col>
							<Col>
								{
									(this.state.showVideoPlayer[i] && val.links.length > 0) ? (
										<Player ref={ player => {
												this.player = player;
											} }>
											<source src={ val.links[this.state.videoLinkToUse].url } />
											<ControlBar autoHide={false} />
										</Player>
									) : (
										<OverlayTrigger placement="right" overlay={ tooltip }>
											<img src={ encodeURI(val.vimeo_thumbnail) } className="videoThumbnail"
												alt={ val.title }
												onClick={ (e) => {
													e.persist();
													this.showVideoPlayer(e)
												} }/>
										</OverlayTrigger>
									)
								}
							</Col>
						</Row>
						<Row className="my-1">
							<Col sm={3} className="text-muted">Uploaded</Col>
							<Col>
								{ (new Date(val.created_at)).toLocaleDateString() }
							</Col>
						</Row>
						<Row className="my-1">
							<Col sm={3} className="text-muted">Likes</Col>
							<Col>
								{ val.like_count }
							</Col>
						</Row>
						<Row className="my-1">
							<Col sm={3} className="text-muted">Loves</Col>
							<Col>
								{ val.love_count }
							</Col>
						</Row>
						<Row className="my-1">
							<Col sm={3} className="text-muted">Points</Col>
							<Col>
								{ val.points }
							</Col>
						</Row>
						<Row className="my-1">
							<Col sm={3} className="text-muted">Entered By</Col>
							<Col>
								{ val.avatar ? <img src={ encodeURI(val.avatar) } className="avatarImage" alt="user avatar"/> : "" }
								{ val.display_name }
							</Col>
						</Row>
						<Row className="my-1">
							<Col sm={3} className="text-muted">Actions</Col>
							<Col>
								<Button onClick={ (e) => { this.handleBlockVideo(e, val.id) } }>Block Video</Button>
								<Button onClick={ (e) => { this.handleUnblockVideo(e, val.id) } }>Unblock Video</Button>
							</Col>
						</Row>

					</Col>
				</Row>
			)
		}) : ( "No videos to display" );
	}

	render() {
		return (
			this.buildVideoRows()
		);
	}
}
