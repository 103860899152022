import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Pagination, Card, Form, Spinner } from 'react-bootstrap';

// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import { confirmAlert } from 'react-confirm-alert';
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

// import axios from "axios";
// import config from "../config";
// import Videos from "../components/Videos.js";



import { getContests } from "../api/Contests.js";




export default class ContestManagement extends Component {

		constructor(props) {
			super(props);

			this.state = {
				isAuthenticated: props.isAuthenticated,
				contestData: [],
				loadingDone: false,
				showEditContestForm: false,
				currentPage: 1,
				maxPage: 99999, // arbitrarily large number of pages
				resultsPerPage: 50,
				toastMsg: "",
				toastSuccess: false,
				toastTime: 7000
			};

			this.getContestsCallback = this.getContestsCallback.bind(this);
			// this.getSingleContestCallback = this.getSingleContestCallback.bind(this);
			// this.putContestsCallback = this.putContestsCallback.bind(this);
			// this.createContestsCallback = this.createContestsCallback.bind(this);
			// this.deleteContestsCallback = this.deleteContestsCallback.bind(this);
			// this.createHostCallback = this.createHostCallback.bind(this);
			// this.deleteHostCallback = this.deleteHostCallback.bind(this);
			// this.createSponsorCallback = this.createSponsorCallback.bind(this);
			// this.deleteSponsorCallback = this.deleteSponsorCallback.bind(this);
			// this.createPrizeCallback = this.createPrizeCallback.bind(this);
			// this.deletePrizeCallback = this.deletePrizeCallback.bind(this);
			// this.handleStartDateChange = this.handleStartDateChange.bind(this);
			// this.handleEndDateChange = this.handleEndDateChange.bind(this);
		}

		componentDidMount() {
			this.refreshContests();
		}

		componentWillUnmount() {

		}


		refreshContests() {
			this.getContestData(this.state.currentPage, this.state.resultsPerPage);
		}

		getContestData(page, size) {
			this.setState({ loadingDone: false });
			getContests(this.getContestsCallback, { page: page, pageSize: size });
		}

		getContestsCallback(data) {
			// store contest data and set maximum page if no data is returned
			this.setState({ contestData: data, loadingDone: true, maxPage: (data.length > 0) ? this.state.maxPage : this.state.currentPage });

			if (this.state.currentContestId) {
				let contestId = this.state.currentContestId;
				let contestData = this.state.contestData;
				// console.log("currentContestId", contestId);

				for(var i = 0; i < contestData.length; i++) {
					if (parseInt(contestData[i].id) === parseInt(contestId)) {
						this.selectIndividualContest(contestData[i]);
					}
				}
			}
		}


		/*
			Display a feedback message to the user
		*/
		/*makeToast(msg, success) {

			this.setState({ toastMsg: msg, toastSuccess: success });
			document.getElementById("toast").scrollIntoView();

			//clear toast after certain amount of time
			setTimeout(() => {
				this.setState({ toastMsg: "", toastSuccess: null });
			}, this.state.toastTime);
		}*/


		render() {
			return (this.state.isAuthenticated) ? this.renderAuth() : this.renderUnauth();
		}

		buildPageControlForm(label) {
			return (
				<Form className="mt-3">
					<Form.Group>
						<Row>
							<Col sm={4}>
								<Row>
									<Col sm={4}></Col>
									<Col sm={8}>
										<Pagination>
											<Pagination.Prev onClick={ () => { this.previousPage() } }/>

											<Pagination.Item>
											{
												this.state.currentPage
											}
											</Pagination.Item>

											<Pagination.Next onClick={ () => { this.nextPage() } }/>
										</Pagination>
									</Col>
								</Row>
							</Col>
							<Col sm={8}>
								<Row>
									<Col sm={4}></Col>
									<Col sm={4} className="text-right">
										<Form.Label className="text-muted">{ label } per page</Form.Label>
									</Col>
									<Col sm={2}>
										<Form.Control as="select" value={ this.state.resultsPerPage } onChange={ (e) => { this.handleResultsPerPageChange(e) } }>
											<option value="50">50</option>
											<option value="25">25</option>
											<option value="10">10</option>
										</Form.Control>
									</Col>
									<Col sm={2}></Col>
								</Row>
							</Col>
						</Row>
					</Form.Group>
					<hr/>
				</Form>
			);
		}


		/*
			Builds the individual contest cards.
		*/
		buildContestCards() {
			return this.state.contestData.map((val, i) => {

				let cardImage;
				if (val.hero_image_web_url) {
					cardImage = encodeURI(val.hero_image_web_url);
				} else if (val.hero_image_phone_url) {
					cardImage = encodeURI(val.hero_image_phone_url);
				}

				return (
					<Col md={4} className="mb-4" key={ val.name }>
						<Card className={"challengeCard"} onClick={ () => { window.location = '/contest-management/'+val.id } }>
							<Card.Img variant="top" src="/img/blank-4x3.svg" className="cardImageContainer" style={{'backgroundImage': 'url('+cardImage+')'}} />
							<Card.Body>
								<Card.Title>{ val.name }</Card.Title>
								<Card.Text>
									{ val.description }
								</Card.Text>
								<Row>
									<Col xs={4} className="contestLabel text-muted">Current?</Col>
									<Col xs={8}>{ (val.contest_current) ? "Yes" : "No" }</Col>
								</Row>
								<Row>
									<Col xs={4} className="contestLabel text-muted">Start Date</Col>
									<Col xs={8}>{ val.start_date }</Col>
								</Row>
								<Row>
									<Col xs={4} className="contestLabel text-muted">End Date</Col>
									<Col xs={8}>{ val.end_date }</Col>
								</Row>
								<Row>
									<Col xs={4} className="contestLabel text-muted">Videos</Col>
									<Col xs={8}>{ val.videos_count }</Col>
								</Row>
								{
									(val.deleted_at) ? (
										<Row>
											<Col xs={4} className="contestLabel text-muted">Deleted On</Col>
											<Col xs={8}>{ val.deleted_at }</Col>
										</Row>
									) : ( "" )
								}
							</Card.Body>
						</Card>
					</Col>
				);
			});
		}

		renderAuth() {
			return (
				<Container>

					<Row>
						<Col className="my-4">
							<h1 className="display-4">Contest Management</h1>
							<hr/>
						</Col>
					</Row>

					<Row>
						<Col sm={2}></Col>
						<Col sm={7}>
							<div id="toast" className={
									((this.state.toastSuccess === true) ? "bg-success" : (
										(this.state.toastSuccess === false) ? "bg-warning" : "bg-info"
									)) + " toastMessage my-3"
								} hidden={ this.state.toastMsg === "" }>

								{
									this.state.toastMsg
								}

							</div>
						</Col>
					</Row>

					{
						(this.state.loadingDone) ? (
							<Fragment>
								<Row className="my-4">
									<Col sm={6}>
										<h4 className="text-muted">All Challenges</h4>
										<span className="contestLabel text-muted">Click on a challenge card to see rounds</span>
									</Col>
									<Col sm={6} className="text-right">
										<a href="/contest-management/create" className="btn btn-lg btn-info my-2">Create New Challenge</a>
									</Col>
								</Row>
								<Row>
									{
										this.buildContestCards()
									}
								</Row>
							</Fragment>
						) : (
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner>
						)
					}

					<Row>
						<Col className="my-4">

							{
								(this.state.showEditContestForm === false) ? (
									this.buildPageControlForm("Challenges")
								) : ( "" )
							}

						</Col>
					</Row>

				</Container>
			);
		}

		renderUnauth() {
			return (
				<div className="mt-3">
					Please log in.
				</div>
			);
		}


		/** Change handlers for paging controls **/
		handleResultsPerPageChange(e) {
			var newSize = parseInt(e.target.value);
			this.setState({ resultsPerPage: newSize });
			this.fetchNewPage(this.state.currentPage, newSize);
		}

		previousPage(e) {
			var newPage = Math.max(1, this.state.currentPage - 1);
			this.setState({ currentPage: newPage  });
			this.fetchNewPage(newPage, this.state.resultsPerPage);
		}

		nextPage(e) {
			var newPage = Math.min(this.state.maxPage, this.state.currentPage + 1);
			this.setState({ currentPage: newPage  });
			this.fetchNewPage(newPage, this.state.resultsPerPage);
		}

		/*
			Fetches a page of data (contest or videos) when a paging parameter changes.
		*/
		fetchNewPage(page, size) {
			// this flag indicates that a single contest is being viewed
			if(this.state.showEditContestForm)
				this.getSingleContestData(this.state.currentContestID, page, size);
			else
				this.getContestData(page, size);
		}
}
